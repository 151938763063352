import React, { useEffect, useState,useContext } from "react";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";
const GoogleMapComponent = (props) => {
    const {latlong,locationValue} = useContext(PropertyContext);
   // console.log(latlong)
  //  console.log(props)
   // console.log(latlong)
    const [lt,setlt]=useState( locationValue[0]?.latlng || {
        lat:  28,
        lng:  77
    })
    useEffect(() => { 
      //  console.log(latlong)
        if(lt?.lat!==latlong?.lat)
    {
        setlt(latlong)
    }},[latlong])
   
    
//  useEffect({
    
//  },[])
    let markersList = [
        { lat: 28, lng: 77 },
       
    ]
    let [markers, setMarkers] = useState(markersList);
    // const mapStyles = {
    //     width: '100%',
    //     height: '100%'
    // };
// console.log(lt)
    let onMarkerDragEnd = (coord, index, markers) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        markers[index] = { lat, lng };
      //  console.log({ lat, lng })
        props.receivelatlong({ lat, lng })
        // let latlnget={ lat, lng }
        setMarkers(markers);
    }
    let myMarkers = markers && Object.entries(markers).map(([key, val]) => (
        <Marker key={key} id={key} position={!isNaN(props?.latlngdet.lat) && props?.latlngdet ||{
            lat: val.lat,
            lng: val.lng
        }} 
            onClick={() => console.log("Clicked")}
            draggable={true}
            onDragend={(t, map, coord) => onMarkerDragEnd(coord, key, markers)}
        />
    ))
   // console.log(lt)
    return (
        <>
            <div>
                 <div className="row d-flex justify-content-center text-center maplatlong">
                    
                    <Map className="maplatlong_map"
                       
                        google={props?.google}
                        zoom={lt?.lat !== 28  ? 15 : 15}
                        // style={{ height: "100px", width: "100%" }}
                        initialCenter={
                             lt
                         }
                        
                        
                        center={
                            props?.latlngdet.lat && props?.latlngdet||lt
                            
                        }
                        // className="map-container"
                        // panControlOptions={country="ind"}
                        
                       
                    >
                        {myMarkers}
                        </Map>
                        
           
                </div>
            </div>
        </>
    );
}
export default GoogleApiWrapper({
    apiKey: 'AIzaSyA64hWNaMkhU56Bz0nix2TOzJJf2DoqT0A',
    region: 'IN'
})(GoogleMapComponent);
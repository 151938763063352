import React, { useState,useMemo,useEffect} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/system';

import pic from "../assets/unnamed.png"
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
// import usePlacesAutocomplete, {
//   getGeocode,
//   getLatLng,
// } from "use-places-autocomplete";
// import Autocomplete from 'react-google-autocomplete';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import axios from 'axios';
import '../styles/propertyclasstitle.css'

import { ArrowCircleUpTwoTone } from '@mui/icons-material';
// import { useLoginFormValidator } from "";

// import Search from './Search';

// import GoogleMap from './GoogleMap';
export default function Propertydet() {
  const [age, setAge] = React.useState('');
  const [PropertyName, setPropertyname] = useState('');
  const [PropertychainName, setPropertychainname] = useState();
  const [GSTNumber,setGSTNumber]=useState('');
  const [PropertyType,setpropertyType]=useState();
  const [Starcategory,setstarcategory]=useState();
  const [propertyAddress,setpropertyAddress]=useState();
  const [newstate,setnewstate]=useState()
  const [newcity,setnewcity]=useState()
  const [newlocality,setnewlocality]=useState();
  const [pincode,setpincode]=useState('');
  const [Directionandlandmark,setDirectionandlandmark]=useState('')
  const [WebsiteAddress,setwebsiteAddress]=useState('');
  const [Detailrequired,setdetailrequired]=useState('')
  let chkin =[1,2,3,4,5,6,7,8,9,10,11,12,24];
  let chkinType =["AM","PM","Hrs"];
  let chkout=[1,2,3,4,5,6,7,8,9,10,11,12,24];
  let chkoutType=["AM","PM","Hrs"];
  const [locality, setlocality] = useState([])
  let [checkin, setcheckin] = useState("");
  let [checkinType,setcheckinType]=useState("")
  let [checkout,setchkout]=useState("");
  let [checkoutType,setchkoutType]=useState("");
  let [location,setlocation]=useState();
  let [locationlat,setlocationlat]=useState("");
  let [locationlng,setlocationlng]=useState("");
  let [CancellationPolicy,setcancellaationpolicy]=useState("")
  let [btccrdperiod,setbtccrdperiod]=useState();
  const [Payeename,setpayeename]=useState('')
  const [Bankname,setBankname]=useState('')
  const [Accountnumber,setaccountnumber]=useState('');
  const [ifsccode,setifsccode]=useState('');
  const [accounttype,setaccounttype]=useState('');
  const [branchaddress,setbranchaddress]=useState('');
  const [upiid,setupiidd]=useState('');
  const [upiname,setupiname]=useState('');
  const [upimobilenumber,setmobilenumber]=useState('');
  const [file, setFile] = useState();
  const [file1,setfile1]=useState();
  const [filename1,setfilename1]=useState();
  

  // const contacttypearray = [
  //   {"ContactType":"",}
  // ];
  // const contactnamearray = [
  //   {"Contactname":"",}
  // ];
  // const designationarray = [
  //   {"designation":"",}
  // ];
  const [contatcttype, setcontacttype] = useState([]);
  const [contactName, setcontactName] = useState([]);
  const [designation,setdesignation]=useState([]);
  const [emailid,setemailid]=useState()
  const [Mobilenumber,setMobilenumber]=useState([]);
  const [cart, setCart] = useState([]);
  const [roomcategory,setroomcategory]=useState([]);
  const [singlerackrate,setsinglerackrate]=useState([]);
  const [doublerackrate,setdoublerackrate]=useState([]);
  const [triplerackrate,settriplerate]=useState([]);
  const [singlediscount,setsinglediscountrate]=useState([]);
  const [doublediscountrate,setdoublediscountrate]=useState([]);
  const [triplediscountrate,settriplediscountrate]=useState([]);
  const [extrapax,setextrapax]=useState([]);
  const [mealplan,setmealplan]=useState([]);
  const [otherinclusions,setotherinclusions]=useState([]);
  const [tacpercentage,settacpercentage]=useState([]);
  const [ValidityFrom,setvalidityfrom]=useState([])
  const [ValidityTo,setValidityTo]=useState([]);
  const [roomphoto,setroomphoto]=useState([]);
  const [roomgroup,setroomgroup]=useState([]);
  const [cart1, setCart1] = useState([]);
  const [statusphoto, setStatusphoto] = useState('')
  const [propctdetList, setpropctdetList] = useState([{ propctdet: "" }]);
  const [fileName, setFileName] = useState("");
  const [imgres,setimgres]=useState([])

  const handlepropctAdd = () => {
   setpropctdetList([...propctdetList, { propctdet: "" }]);
  };

  // const [selectedFile, setSelectedFile] = useState();
	// const [isFilePicked, setIsFilePicked] = useState(false);

	// const changeHandler = (event) => {
	// 	setSelectedFile(event.target.files[0]);
	// 	setIsSelected(true);
	// };
  
    // const { errors, validateForm, onBlurField } = useLoginFormValidator(form);
    // const onUpdateField = e => {
    //   const field = e.target.name;
    //   const nextFormState = {
    //     ...form,
    //     [field]: e.target.value,
    //   };
    //   setForm(nextFormState);
    //   if (errors[field].dirty)
    //     validateForm({
    //       form: nextFormState,
    //       errors,
    //       field,
    //     });
    //   }

    // const handleFileChange = (e) => {
    //   const img = {
    //     preview: URL.createObjectURL(e.target.files[0]),
    //     data: e.target.files[0]
        
        
    //   }
    //   setroomphoto(img),
    //   // setFileName(e.target.files[0].name)
    // } 
  const[latlng,setlatlng]=React.useState('');
 
  const [state, setstate] = useState([])
    const [statevalue,setstatevalue]=useState([])


  useEffect(() => {
    const loadstates = async () => {
    const response = await axios.post('https://hotelonboardtestapi.staysimplyfied.com/API/selectstate');
   
    console.log(response.data.data)
    setstate(response.data.data)
    
    }
    loadstates()
    }, [])

 

    useEffect(() => {
      const loadstates = async () => {
      const response = await axios.post('https://hotelonboardtestapi.staysimplyfied.com/API/selectcity');
      console.log(response.data.data)
      setcity(response.data.data)
      }
      loadstates()
      }, [])
      // console.log(city)
      
      useEffect(() => {
        const loadstates = async () => {
        const response = await axios.post('https://hotelonboardtestapi.staysimplyfied.com/API/selectlocality');
        console.log(response.data.data)
        setlocality(response.data.data)
        }
        loadstates()
        }, [])
    // console.log(state)
    const handleChangestate = (event, state) => {
     let stateid=state.Id
      console.log(stateid);
      // localStorage.setItem("select state",state.Id)
     
      // return  <h1>{stateid}</h1>
      
    };
    // debugger;
    // let savefile;
    // const saveFile = (e) => {
    //   setFile(e.target.files[0]);
    //   setFileName(e.target.files[0].name);
    // };
    
    const submitpropcreation=()=>{
     
     
     
      const data = {
        PropertyName:PropertyName,
        PropertychainName:PropertychainName,
        GSTNumber:GSTNumber,
        PropertyType:PropertyType,
        Starcategory:Starcategory,
        propertyAddress:propertyAddress,
        state:state,
        city:city,
        
        pincode:pincode,
        value:value,
        Directionandlandmark:Directionandlandmark,
        checkin:checkin,
        checkinType:checkinType,
        checkout:checkoutType,
        CancellationPolicy:CancellationPolicy,
        
        WebsiteAddress:WebsiteAddress,
        btccrdperiod:btccrdperiod,
        Detailrequired:Detailrequired,
        locality:locality,
        Payeename:Payeename,
        Bankname:Bankname,
        Accountnumber:Accountnumber,
        ifsccode:ifsccode,
        accounttype:accounttype,
        branchaddress:branchaddress,
        upiid:upiid,
        upiname:upiname,
        upimobilenumber:upimobilenumber,
        
        // roomgroup:roomgroup,
        cart1:cart1,
        cart:cart,
       }
    
      let response= axios.post('http://localhost:3000/api/propheaderins',data)
      .then((res) => {
      //  alert(res.data)
          console.log(res)
      }).catch((error) => {
          console.log(error)
          console.log(data)
      });
    }
    
    

    const uploadFile =  () => {
   
     
        setCart1(prevgroup=>[...prevgroup],roomcategory,singlerackrate,doublerackrate,triplerackrate,
          singlediscount,doublediscountrate,triplediscountrate,
          extrapax,mealplan,otherinclusions,tacpercentage,ValidityFrom,ValidityTo)
    };
    // console.log(roomgroup)

  
    // if (response) setStatusphoto(response.statusText)
    // console.log(data)
      




 
    
  
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  // const [validated, setValidated] = useState(false);
  // let type = null;
  // let options = null;
  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive); 
   };
  // const [value, setValue] = useState(null);
  const handleChangechekin = (e) => {
    setcheckin(checkin[+e.target.value]);
  };

  // if (checkin !== 24) {
  //   type = checkin;
  // } else if (meridian === "hrs") {
  //   type = meridian;
  // } 
  
  // if (!isLoaded) return <div>Loading...</div>;
  const [textInput, setTextInput] = React.useState('');
  const [city, setcity] = useState([])

  useEffect(() => {
    const loadstates = async () => {
    const response = await axios.post('http://localhost:3000/api/selectcity');
    console.log(response.data.data)
    setcity(response.data.data)
    }
    loadstates()
    }, [])

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => {
        getLatLng(results[0])
        console.log(results[0])
       
      })
      .then(({ lat, lng }) => {
        const stringlatlng=JSON.stringify({lat})
        const str = stringlatlng.replace(/[{}]/g,"");
        const stringlatlng1=JSON.stringify({lng})
        const str1 = stringlatlng1.replace(/[{}]/g,"");
        // {Object.keys(lat).map((key)=>(<h4>{key}{lat}</h4>))}
        setlocationlat(str)
        setlocationlng(str1)
        console.log("📍 Coordinates: ", { lat, lng });
     
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      return (
        <ul class="list-group">
        <li className="list-group-item recommendelist" key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
          
        </li>
        </ul>
      );
    });
   
    let addNewItem;
  addNewItem = () => {
    setCart(prevState => [...prevState, contatcttype,contactName,designation,emailid,Mobilenumber]);
    // console.log(roomgroup)

  };
  let addnewgroup;
  addnewgroup =()=>{
     const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", fileName);

       let response= axios.post("http://localhost:3000/api/imageupload",formData)
      .then((res) => {
        // let resimp=[]
        // resimp=res;
        //   console.log(resimp)
          console.log(res)
          let responsenew=[];
          responsenew=res
          alert(res)
          setimgres(responsenew)
          console.log(imgres)
      }).catch((error) => {
          console.log(error)
          // console.log(formdata)
      });
        // console.log(res);
        console.log(imgres)
        
        
        
    setCart1(prevState => [...prevState,roomcategory,singlerackrate,doublerackrate,triplerackrate,singlediscount,
    doublediscountrate,triplediscountrate,extrapax,mealplan,otherinclusions,tacpercentage,ValidityFrom,ValidityTo]);
      
  }
  
  return (
  <>
    <nav class="navbar navbar-expand-lg navbar-light bg-light" >
  {/* <div class="container-fluid"> */}
    <a class="navbar-brand" href="#"><img src={pic} alt="" width={"60px"} height={"35px"}/></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
   
  {/* </div> */}
</nav>
<div className="container  " >
  
<div class="col-sm-12   align-items-center justify-content-center">
<div id="smartwizard" className='wizard' >
    <ul class="nav">
        <li class="nav-item">
          <a class="nav-link " href="#step-1">
          <div class="num">1</div> 
            Property Details
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#step-2">
            <span class="num">2</span>
           Tariff details
          </a>
        </li>
      
        
    </ul>
 
    <div class="tab-content">
        <div id="step-1" class="tab-pane" role="tabpanel" aria-labelledby="step-1">
            
      <div class="accordion " id="accordionExample">
  <div class="accordion-item ">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Property Info
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body " >
      <div className="col-lg-12 overflow-auto fixedaccordian">
       <form id="propcreationform" className='needs-validation' noValidate  enctype="multipart/form-data" >
          
        <div className="row ">
         
       
        <div className="col-sm-4 ">
        <TextField required label="Property Name" 
        name='PropertyName' id="PropertyName" variant="standard"
        onChange={(e)=>{setPropertyname(e.target.value)}} autoComplete='off' />
        {/* {<p style={{color:'red'}}>Property Name  is required.</p>} */}
        </div>
        <div className="col-sm-4 col-lg-4 aligntop startleft">
        <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id='propertyChainName' >Property Chain Name</InputLabel>
        
        <Select
          labelId="propertyChainName"
          id='propertyChainName'
          name='PropertychainName'
          value={PropertychainName}
          onChange={(e)=>{setPropertychainname(e.target.value)}}
          
          label="Property Chain Name"
        >
          
          <MenuItem value="">
         
          </MenuItem>
          <MenuItem value={"Regular"}>Regular</MenuItem>
          <MenuItem value={"Sarovar Group"}>Sarovar Group</MenuItem>
          <MenuItem value={"Fortune Group ( ITC )"}>Fortune Group ( ITC )</MenuItem>
          <MenuItem value={"Starwood Hotels Group"}>Starwood Hotels Group</MenuItem>
          <MenuItem value={"Genx Group of Hotels"}>Genx Group of Hotels</MenuItem>
          <MenuItem value={"ITC Hotels Group"}>ITC Hotels Group</MenuItem>
          <MenuItem value={"Hilton Group of Hotel"}>Hilton Group of Hotel</MenuItem>
          <MenuItem value={"Siesta Hotels Group"}>Siesta Hotels Group</MenuItem>
          <MenuItem value={"Treebo Group"}>Treebo Group</MenuItem>
          <MenuItem value={"Ziprooms Group ( Spree Hospitality )"}>Ziprooms Group ( Spree Hospitality )</MenuItem>
          <MenuItem value={"Trustedstay Apartment Group"}>Trustedstay Apartment Group</MenuItem>
          <MenuItem value={"Alcove Apartment Group"}>Alcove Apartment Group</MenuItem>
          <MenuItem value={"FabHotels Group"}>FabHotels Group</MenuItem>
          <MenuItem value={"Hyatt Hotels Group"}>Hyatt Hotels Group</MenuItem>
          <MenuItem value={"Best Western Group"}>Best Western Group</MenuItem>
          <MenuItem value={"Vivanta Group ( TATA )"}>Vivanta Group ( TATA )</MenuItem>
          <MenuItem value={"Lemon Tree Group"}>Lemon Tree Group</MenuItem>
          <MenuItem value={"Fern Group of Hotel"}>Fern Group of Hotel</MenuItem>
          <MenuItem value={"Carlson  Group"}>Carlson  Group</MenuItem>
          <MenuItem value={"Accor Group"}>Accor Group</MenuItem>
          <MenuItem value={"Choice Hotel Group"}>Choice Hotel Group</MenuItem>



        </Select>
        </FormControl>
        {/* {<p style={{color:'red'}}>Property Chain Name  is required.</p>} */}
        </div>
        
        <div className="col-sm-4">
        <TextField 
        id="GSTNumber"  
        label="GST Number" 
        variant="standard" 
        name="GSTNumber"
        value={GSTNumber}
        onChange={(e)=>{setGSTNumber(e.target.value)}}
        />
        {/* { <p style={{color:'red'}}>GSTNumber is required.</p>} */}
        <button  class="btn btn-primary">Verify</button>
        </div>
       
       </div>
        
        <div className="row">
          <div className="col-sm-4  aligntop startleft">
          <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id="demo-simple-select-standard-label">Property Type</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="PropertyType"
          value={PropertyType}
          onChange={(e)=>{setpropertyType(e.target.value)}}
          name="PropertyType"
          
          label="PropertyType"
        >
          <MenuItem value="">
         
          </MenuItem>
          <MenuItem value={"Hotel"}>Hotel</MenuItem>
          <MenuItem value={"Service Apartment"}>Service Apartment</MenuItem>
          <MenuItem value={"Service Apartment"}>Resort</MenuItem>
        </Select>
        </FormControl>
        
          </div>
          <div className="col-sm-4  aligntop ">
          <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id="demo-simple-select-standard-label"> Star Category</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={Starcategory}
          name='Starcategory'
          onChange={(e)=>{setstarcategory(e.target.value)}}
          
          
          label="Property Chain Name"
        >
          <MenuItem value="">
         
          </MenuItem>
          <MenuItem value={"PG/Hotel"}>PG/Hostel</MenuItem>
          <MenuItem value={"1star"}>1star</MenuItem>
          <MenuItem value={"2star"}>2star</MenuItem>
          <MenuItem value={"3star"}>3star</MenuItem>
          <MenuItem value={"4star"}>4star</MenuItem>
          <MenuItem value={"5star"}>5star</MenuItem>
        </Select>
        </FormControl>
        
          </div>
          <div className="col-sm-4">
          <TextField
          id="multiline-static"
          label="Address"
          name='propertyAddress'
          value={propertyAddress}
          onChange={(e)=>{setpropertyAddress(e.target.value)}}
          multiline
          rows={2}
        />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4  ">
   
          <Autocomplete
  disablePortal
  id="combo-box-demo"
  getOptionLabel={(state)=>`${state.StateName}`}
  options={state}
  // value={state}
  onChange={(e)=>{setstate(e.target.value)}}
  // stateiddisplay={handleChange}
  noOptionsText={"No States Available"}
  isOptionEqualToValue={(option,value)=>option.StateName==value.StateName}
  sx={{ width: 220 }}
  renderOption={(props,state)=>(
    <Box component="li" {...props} key={state.id}>
        {state.StateName}
       
    </Box>
  )}
  renderInput={(params) => <TextField {...params} label="Select State" />}
/>
          </div>
          <div className="col-sm-4 aligntop ">
          <Autocomplete
  disablePortal
  id="combo-box-demo"
  getOptionLabel={(city)=>`${city.CityName}`}
  options={city}
  // value={city}
  // onFocus={displaystateid}
  onChange={(e)=>{setcity(e.target.value)}}
  noOptionsText={"No States Available"}
  sx={{ width: 220 }}
  renderOption={(props,city)=>(
    <Box component="li" {...props} key={city.id}>
        {city.CityName}
    </Box>
  )}
  renderInput={(params) => <TextField {...params} label="Select city" />}
/>

          </div>
          <div className="col-sm-4 mt-sm-2 startleft">
          <Autocomplete
  disablePortal
  id="combo-box-demo"
  getOptionLabel={(locality)=>`${locality.Locality}`}
  options={locality}
  // value={locality}
  onChange={(e)=>{setlocality(e.target.value)}}
  noOptionsText={"No States Available"}
  sx={{ width: 220 }}
  renderOption={(props,locality)=>(
    <Box component="li" {...props} key={locality.Id}>
        {locality.Locality}
    </Box>
  )}
  renderInput={(params) => <TextField {...params} label="Select Locality" />}
/>
          
          
          </div>
          

        </div>
        <div className="row">
        <div className="col-sm-4 ">
        <TextField name='pincode' 
        id="standard-basic" 
        value={pincode}
        
        onChange={(e)=>{setpincode(e.target.value)}}
        label="Pincode" variant="standard" />
    
        </div>
        <div className="col-sm-4  ">
       
        <div ref={ref}>
      <input
         value={value}
        //  onChange={handleInput}
         onChange={(e)=>{setValue(e.target.value)}}
        name='location'
        disabled={!ready}
        placeholder="Search location"
        autoComplete='off'
      />
      {status === "OK" && <ul>{renderSuggestions()}</ul>}
    </div>
      
       <input value={locationlat}  />
       <input value={locationlng}  />

        </div>
        {/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Search Location</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body "   style={{height: '90%'}}> */}
      {/* <Map/> */}
      {/* <Autocomplete
    style={{width: '90%'}}
    onPlaceSelected={(place) => {
      console.log(place);
    }}
    types={['(regions)']}
    componentRestrictions={{country: "ind"}}
/> */}
     {/* <Search/> */}
     {/* <GoogleMap/> */}
      {/* </div>
   
    </div>
  </div>
</div> */}
 <div className="col-sm-4 startleft">
 <TextField
          id="multiline-static"
          label="Direction & Landmarks"
          name='Directionandlandmarks'
          value={Directionandlandmark}
          onChange={(e)=>{setDirectionandlandmark(e.target.value)}}
          multiline
          rows={2} 
        />
 </div>
 </div>
 <div className="row">
 <div className="col-sm-2 aligntop startleft">
          <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id="demo-simple-select-standard-label">Check-In Time</InputLabel>
        <Select
        name='checkintime'
          style={{maxHeight: '300px', overflow: 'auto'}}
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={checkin}
          onChange={(e)=>{setcheckin(e.target.value)}}
          label="check-In Time "
        >
          {chkin.map((chk, index) => (<MenuItem key={index} value={index}> {chk}</MenuItem>  ))}
               </Select>
        </FormControl>
    </div>
    <div className="col-sm-2 aligntop ">
          <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
        <InputLabel id="demo-simple-select-standard-label">Check-In Type</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={checkinType}
          name='checkinType'
          MenuProps={{
            PaperProps: { sx: { maxHeight: 200 }}
          }}
          
          onChange={(e)=>{setcheckinType(e.target.value)}}
          
          
        >
           {chkinType.map((merid, index) => (<MenuItem key={index} value={index}> {merid}</MenuItem>  ))}
          

        </Select>
        </FormControl>
    </div>
    <div className="col-sm-2 aligntop startleft">
          <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
        <InputLabel id="demo-simple-select-standard-label">Check-Out Time</InputLabel>
        <Select
          name='checkouttime'
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={checkout}
          onChange={(e)=>{setchkout(e.target.value)}}
          label="Select City"
        >
          {chkout.map((merid, index) => (<MenuItem key={index} value={index}> {merid}</MenuItem>  ))}
       
        </Select>
        </FormControl>
    </div>
    <div className="col-sm-2 aligntop startleft">
          <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
        <InputLabel id="demo-simple-select-standard-label">Check-Out Type</InputLabel>
        <Select
          name='checkouttype'
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={checkoutType}
          onChange={(e)=>{setchkoutType(e.target.value)}}
          label="Select City"
        >
          {chkoutType.map((merid, index) => (<MenuItem key={index} value={index}> {merid}</MenuItem>  ))}
       
        </Select>
        </FormControl>
    </div>
    <div className="col-sm-4 mt-sm-2 ">
   <TextField
   name='Cancellationpolicy'
   id="multiline-static"
   label="Cancellation Policy"
   multiline
   rows={2}
   value={CancellationPolicy}
   onChange={(e)=>{setcancellaationpolicy(e.target.value)}}
 />
  </div> 
 </div>

      <div className="row">
         <div className="col-sm-4"> 
         <TextField 
         name='websiteAddress'
         id="standard-basic"
         value={WebsiteAddress}
         onChange={(e)=>{setwebsiteAddress(e.target.value)}} 
          label="Website Address" variant="standard" />
      </div>
      <div className="col-sm-4">
      <label for="formFile"  class="form-label">Photos</label>
      
              <input class="form-control fprops " accept="image/*"  
               type="file" 
               onChange={e => {
                setfile1(e.target.files[0]);
                console.log(e.target.files[0])
                
                setfilename1(e.target.files[0].name);
               }}
               multiple/>
        
       
       {/* <input class="form-control fprops"  placeholder='Photos' type="file" id="formFileMultiple" /> */}
   </div>
   <div className="col-sm-4">
          <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
        <InputLabel id="demo-simple-select-standard-label"> BTC Credit Period</InputLabel>
        <Select
          name='btccreditperiod'
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={btccrdperiod}
          onChange={(e)=>{setbtccrdperiod(e.target.value)}} 
          label="Account Type"
        >
          
          <MenuItem value={"0"}>0</MenuItem>
          <MenuItem value={"1"}>1</MenuItem>
          <MenuItem value={"2"}>2</MenuItem>
          <MenuItem value={"3"}>3</MenuItem>
          <MenuItem value={"4"}>4</MenuItem>
          <MenuItem value={"5"}>5</MenuItem>
          <MenuItem value={"6"}>6</MenuItem>
          <MenuItem value={"7"}>7</MenuItem>
          <MenuItem value={"8"}>8</MenuItem>
          <MenuItem value={"9"}>9</MenuItem>
          <MenuItem value={"10"}>10</MenuItem>
          <MenuItem value={"11"}>11</MenuItem>
          <MenuItem value={"12"}>12</MenuItem> 
          <MenuItem value={"13"}>13</MenuItem> 
          <MenuItem value={"14"}>14</MenuItem>
          <MenuItem value={"15"}>14</MenuItem>
         
        </Select>
        </FormControl>
          </div>
  
   </div>
   <div className="row">
          
        
          
          <div className="col-sm-4">
          <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
        <InputLabel id="demo-simple-select-standard-label">Detail Required</InputLabel>
        <Select
          name='detailrequired'
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={Detailrequired}
          onChange={(e)=>{setdetailrequired(e.target.value)}} 
          label="Account Type"
        >
          
          <MenuItem value={"1star"}>PMS Partner</MenuItem>
          <MenuItem value={"1star"}>GDS Partner</MenuItem>
          <MenuItem value={"1star"}>Channel Manager</MenuItem>
          <MenuItem value={"1star"}>Inventory Distribution to OTA Partners</MenuItem>
         
         
        </Select>
        </FormControl>
          </div>
      </div>   
 
 
      </form>

 
 
        </div>
       
        <div className="row">
  <div className="col-12"> 
      <button   class="btn btn-primary float-end  collapsed"
         
        type="button"   data-bs-toggle="collapse"
        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Next</button>
   </div>
 
 </div>

    
    </div> 
    
  </div>
  
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Bank Info
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
         <div className="row">

           <div className="col-sm-4 ">
                 <TextField 
                 name='payeename'
                 id="standard-basic"
                 label="Payee Name" 
                 variant="standard"
                 value={Payeename}
                 onChange={(e)=>{setpayeename(e.target.value)}}  

                 />
            </div>
            <div className="col-sm-4 ">
                 <TextField 
                 name='bankname'
                 id="standard-basic" 
                 label="Bank Name" 
                 variant="standard" 
                 value={Bankname}
                 onChange={(e)=>{setBankname(e.target.value)}}  
                 />
            </div>
            <div className="col-sm-4 ">
                 <TextField 
                 name='Accountnumber'
                 id="standard-basic" 
                 label="Account Number" 
                 variant="standard" 
                 value={Accountnumber}
                 onChange={(e)=>{setaccountnumber(e.target.value)}} 
                 />
            </div>
        </div>
        <div className="row">
          <div className="col-sm-4">
          <TextField 
            name='ifsccode'
            id="standard-basic" 
            label="IFSC Code" 
            variant="standard" 
            value={ifsccode}
            onChange={(e)=>{setifsccode(e.target.value)}} 
            />
          </div>
          <div className="col-sm-4 aligntop ">
          <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id="demo-simple-select-standard-label">Account Type</InputLabel>
        <Select
          name='AccountType'
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={accounttype}
          onChange={(e)=>{setaccounttype(e.target.value)}} 
          label="Account Type"
        >
          
          <MenuItem value={"Hotel"}>Saving</MenuItem>
          <MenuItem value={"1star"}>Current</MenuItem>
          <MenuItem value={"1star"}>Escrow A/C</MenuItem>
         
        </Select>
        </FormControl>
          </div>
          <div className="col-sm-4 mt-sm-2">
            
          <TextField
          name='branchAddress'
          id="multiline-static"
          label="Branch Address"
          value={branchaddress}
          onChange={(e)=>{setbranchaddress(e.target.value)}} 
          multiline
          rows={2}
        />
          </div>
        </div>
        <div className="row">

<div className="col-sm-4 ">
     <TextField 
     id="standard-basic" 
     label="UPI ID" 
     variant="standard" 
     name='upiid'
     value={upiid}
     onChange={(e)=>{setupiidd(e.target.value)}} 
     />
</div>
<div className="col-sm-4 ">
       <TextField
       name='upiname' 
       id="standard-basic" 
       label="UPI Name" 
       variant="standard" 
       value={upiname}
       onChange={(e)=>{setupiname(e.target.value)}} 
       />
</div>
<div className="col-sm-4 ">
     <TextField 
     name='upimobilenumber'
     id="standard-basic" 
     label="UPI Mobile Number"
      variant="standard" 
      value={upimobilenumber}
      onChange={(e)=>{setmobilenumber(e.target.value)}}
      />
</div>
</div>
        <div className="row mt-sm-2">
  <div className="col-12">

  <button  class="btn btn-primary ms-sm-3 float-end collapsed" 
  type="button" data-bs-toggle="collapse" 
  data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Next</button>
   <button  class="btn btn-primary  float-end collapsed" 
  type="button" data-bs-toggle="collapse" 
  data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">Previous</button>
  </div>
  

 
 
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Property Contact Info
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body ">
       
     <div className="table-responsive">
        <table class="table table-bordered">
  <thead>

    <tr id='propctdet' >
      <th scope="col" >
       
      <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
        <InputLabel id="demo-simple-select-standard-label">Contact Type</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id=""
          value={contatcttype}
          onChange={(e)=>{setcontacttype(e.target.value)}}
          label="Account Type"
        >
          
          <MenuItem value={"Hotel"}>Sales</MenuItem>
          <MenuItem value={"1star"}>Front Office</MenuItem>
          <MenuItem value={"1star"}>Reservation</MenuItem>
          <MenuItem value={"1star"}>Central Reservation</MenuItem>
          <MenuItem value={"1star"}>GM</MenuItem>
          <MenuItem value={"1star"}>VP/Director Sales</MenuItem>
          <MenuItem value={"1star"}>FOM/DM</MenuItem>
          <MenuItem value={"1star"}>Hotel Whats App number</MenuItem>
         
        </Select>
        </FormControl>
      </th>
      <th scope="col"> <TextField id="standard-basic" value={contactName} 
      onChange={(e)=>{setcontactName(e.target.value)}}  label="ContactName" variant="standard" /></th>
      <th scope="col"> <TextField id="standard-basic" value={designation} 
      onChange={(e)=>{setdesignation(e.target.value)}} label="Designation" variant="standard" /> </th>
      <th scope="col"> <TextField id="standard-basic" value={emailid} onChange={(e)=>{setemailid(e.target.value)}} label="E-mail Id" variant="standard" /></th>
      <th scope="col"> <TextField id="standard-basic" value={Mobilenumber} onChange={(e)=>{setMobilenumber(e.target.value)}}  label="Mobile Number" variant="standard" /></th>
           
      <th scope="col"> <button  
      class="btn btn-primary  float-end collapsed"
      onClick={addNewItem}
      type="button">Add</button></th>
      
  </tr>
  <tr>
    {cart.map((Items, Index) => (
      <>
          <td key={Index}> {Items}</td>
          </>
       ))}
   </tr>
      
    
    
    
     
 
  </thead>
  <tbody>
  
   
  </tbody>
</table>
</div>
     
  

     


      
    
      </div>

      <div className="row">
        <div className="col-sm-12">
       
   <button  class="btn btn-primary  float-end collapsed" 
  type="button" data-bs-toggle="collapse" 
  data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Previous</button>

        </div>
      </div>
      

      </div>
    </div>
    </div>
  </div>
            
            
            
            
            

          </div>
          
          <div id="step-2" class="tab-pane" role="tabpanel" aria-labelledby="step-2">
         
    
          <table class="table table-bordered">
  <thead>
    <tr>
      <th scope="col" >
           Room Category
      {/* <TextField id="standard-basic" label="Room Category" variant="standard" /> */}
      </th>
      <th scope="col" className='text-center' colSpan={3}> 
      Rack Rate
      {/* <TextField id="standard-basic" label="Single tariff" variant="standard" /> */}
      </th>
      <th scope="col" className='text-center' colSpan={3}>  Discount Rate</th>
      <th scope="col"> Extra Pax  </th>
      <th scope="col"> Meal plan  </th>
      <th scope="col"> Other Inclusions</th>
      <th scope="col">  TAC % </th>
      <th scope="col"> Validity From</th>
      <th scope="col"> Validity To </th>
      <th scope="col"> Room Photo  </th>
      <th scope="col"> </th>
    </tr>
    <tr>
      <td scope="col" >
           
      <TextField id="standard-basic" label="Room Category" 
      value={roomcategory}
      onChange={(e)=>{setroomcategory(e.target.value)}} 
       variant="standard" />
      </td>
      <td scope='col' width={"70px"}> 
      {/* Single */}
      <TextField id="standard-basic" label="Single"
      value={singlerackrate} 
      onChange={(e)=>{setsinglerackrate(e.target.value)}}  
      variant="standard" />
      </td>
      <td scope='col' width={"70px"}> 
      
      <TextField id="standard-basic" label="Double" 
      value={doublerackrate}
      onChange={(e)=>{setdoublerackrate(e.target.value)}} 
       variant="standard" />
      </td>
      <td scope='col' width={"70px"}> 
      <TextField id="standard-basic" label="Triple" 
      value={triplerackrate}
      onChange={(e)=>{settriplerate(e.target.value)}}  
      variant="standard" />
     
      </td>
      <td scope="col" width={"70px"}>  <TextField id="standard-basic" label="Single" 
      value={singlediscount}
      onChange={(e)=>{setsinglediscountrate(e.target.value)}}  
      variant="standard" /></td>
      <td scope="col" width={"70px"}> <TextField id="standard-basic" label="Double"
      value={doublediscountrate}
       onChange={(e)=>{setdoublediscountrate(e.target.value)}}  variant="standard" /></td>
      <td scope="col" width={"70px"}> <TextField id="standard-basic" 
      label="Triple" value={triplediscountrate}
      onChange={(e)=>{settriplediscountrate(e.target.value)}}  
      variant="standard" /></td>
      <td scope="col">  
      <TextField id="standard-basic" label="Extra Pax" value={extrapax}
      variant="standard" onChange={(e)=>{setextrapax(e.target.value)}}  />
      </td>
      <td scope="col" width={"80px"}> 
      <FormControl variant="standard" sx={{ m: 1, minWidth: 60 }}>
        <InputLabel id="demo-simple-select-standard-label">Meal Plan</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={mealplan}
          onChange={(e)=>{setmealplan(e.target.value)}} 
          
          label="Account Type"
        >
          
          <MenuItem value={"EP ( No Breakfast)"}>EP ( No Breakfast)</MenuItem>
          <MenuItem value={"CP ( Breakfast)"}>CP ( Breakfast)</MenuItem>
          <MenuItem value={"MAP (Breakfast, Lunch /Dinner)"}>MAP (Breakfast, Lunch /Dinner)</MenuItem>
          <MenuItem value={"AP (Breakfast, Lunch and Dinner)"}>AP (Breakfast, Lunch and Dinner)</MenuItem>
         
        </Select>
        </FormControl>
      </td>
      <td scope="col" > 
      <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
        <InputLabel id="demo-simple-select-standard-label">Other Inclusions</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={otherinclusions}
          onChange={(e)=>{setotherinclusions(e.target.value)}} 
          
          label="Account Type"
        >
          
          <MenuItem value={"WIFI"}>WIFI</MenuItem>
          <MenuItem value={"Gym"}>Gym</MenuItem>
          <MenuItem value={"Pool"}>Pool</MenuItem>
        
         
        </Select>
        </FormControl>
      </td>
      <td scope="col"> <TextField id="standard-basic" 
      label="TAC %" onChange={(e)=>{settacpercentage(e.target.value)}}  value={tacpercentage}
      variant="standard" /></td>
      <td scope="col"> <TextField id="standard-basic" 
      label="Validity From" onChange={(e)=>{setvalidityfrom(e.target.value)}}  
      value={ValidityFrom}
      variant="standard" /> </td>
      <td scope="col"> <TextField id="standard-basic" 
      label="Validity To" value={ValidityTo}
      onChange={(e)=>{setValidityTo(e.target.value)}}  variant="standard" /> </td>
      <td scope="col"> <input class="form-control fpropsnew"  onChange={e => {
                setFile(e.target.files[0]);
                setFileName(e.target.files[0].name);
               }}  placeholder='Photos' type="file"  /></td>
      <th scope="col"> <button  class="btn btn-primary  float-end "  onClick={addnewgroup} type="button">Add</button></th>
      
    </tr>
    <tr>
   
       <td>{roomphoto.preview && <img src={roomphoto.preview} width='75' height='75' />}</td>
       
    </tr>
    <tr>
    { 
    cart1.map((Items, Index) => (
      <>
          <td key={Index}> {Items}</td>
      </>
       ))}
   </tr>
  </thead>
  <tbody>
    <tr>
   <td colSpan={15}>
  <div className='row'>
        <div className="col-12">
    {/* <input type="text" value={imgres}/> */}
    {/* <div key={data}>
    {imgres.map((item) => (
        <li>{item.data} </li>
      ))}
      </div> */}
      <button  class="btn btn-primary float-end mb-4" onClick={submitpropcreation}>Save</button>      
        </div>
      </div>
      </td>
      </tr> 
    <div className="row">
     <h4>{statusphoto}</h4>
    </div>
     
  </tbody>
  
</table>
              
        
        </div>
    
       
        
      </div>
  
       
    </div>
  
   
    <div class="progress">
      <div class="progress-bar" role="progressbar"  aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </div>
 
</div>
<footer class="footer mt-sm-auto " >
  <div className='card'>
    <div class="container ">
      <div className="card-body">
       <span class="text-muted pb-sm-1">Hummingbird • Digital Private Limited</span>
       
       <div className=" float-sm-end">
       <span class="text-muted pb-sm-1">Copywright © 2022 • All Rights Reserved</span>
       </div>
       </div>
     </div>
  </div>
</footer>




  </>
  
  )
 
  function Map() {
    const center = useMemo(() => ({ lat: 11, lng: 80 }), []);
    const [selected, setSelected] = useState(null);
  
    return (
      <>
      {/* <div className="places-container">
      <PlacesAutocomplete setSelected={setSelected} />
    </div> */}
  
      {/* <GoogleMap zoom={10} center={center} mapContainerClassName="map-container">
         {selected && <Marker position={selected} />}
      </GoogleMap> */}
      </>
    );
  }
 
}

// const PlacesAutocomplete = ({ setSelected }) => {
//   const {
//     ready,
//     value,
//     setValue,
//     suggestions: { status, data },
//     clearSuggestions,
//   } = usePlacesAutocomplete();

//   const handleSelect = async (address) => {
//     setValue(address, false);
//     clearSuggestions();

//     const results = await getGeocode({ address });
//     const { lat, lng } = await getLatLng(results[0]);
//     setSelected({ lat, lng });
//   };

//   return (
//     <>
// <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
//         <InputLabel id="demo-simple-select-standard-label">Detail Required</InputLabel>
//         <Select
//           labelId="demo-simple-select-standard-label"
//           id="demo-simple-select-standard"
//           value={value}
//           onChange={(e) => setValue(e.target.value)}
          
//           label="Account Type"
//         >
//            {status === "OK" &&
//             data.map(({ place_id, description }) => (
//               <MenuItem key={place_id} value={description} />
//             ))}
          
         
         
         
//         </Select>
//         </FormControl>


//     </>
//   );
// };

// import React, { useState } from "react";

// import "./App.css";
// import Axios from "axios";

// function App() {
//   const [name, setName] = useState();
//   const [file, setFile] = useState();

//   return (
//     <div className="App">
//       <header className="App-header">
//         <form action="#">
//           <div className="flex">
//             <label htmlFor="name">Name</label>
//             <input
//               type="text"
//               id="name"
//               onChange={event => {
//                 const { value } = event.target;
//                 setName(value);
//               }}
//             />
//           </div>
//           <div className="flex">
//             <label htmlFor="file">File</label>
//             <input
//               type="file"
//               id="file"
//               accept=".jpg"
//               onChange={event => {
//                 const file = event.target.files[0];
//                 setFile(file);
//               }}
//             />
//           </div>
//         </form>
//         <button onClick={send}>Send</button>
//       </header>
//     </div>
//   );
// }

// export default App;

// const send = event => {
//   const data = new FormData();
//   data.append("name", name);
//   data.append("file", file);

//   Axios.post("https://httpbin.org/anything", data)
//     .then(res => console.log(res))
//     .catch(err => console.log(err));
// };
 
import { useState, useEffect, useRef, useContext } from "react";
import {
  Button,
  Card,
  Input,
  Radio,
  Steps,
  Rate,
  Select,
  Checkbox,
  Modal,
  message,
  Spin,
  AutoComplete,
} from "antd";
import "../styles/propinfo.css";
import "antd/dist/antd.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import bagpic from "../assets/welcome.png";
import React from "react";
import axios from "axios";
import "../styles/propinfo.css";
import { CheckOutlined } from "@ant-design/icons";
import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";
import { RoughNotation, RoughNotationGroup } from "react-rough-notation";

export default function Propcreation({ setpropname, setgstnum }) {
  const format = "HH A";
  let hrs = "08";
  let Am = "AM";
  const {
    basic,
    addToBasic,
    addToLocations,
    locationValue,
    addToFacilities,
    addToRoomDetails,
    addToPropImages,
    addToProfile,
    basicverify,
    upiNamefunc,
    basic_Verifiedbut,
    basic_GST_verified,
    bankNamefunc,
    profile_acVerifiedbut,
    profile_upi_Verifiedbut,
    propInput,
    multispacetrim,
    idforUpdationfunc,
    changescheckingfunc,
    propertyaggreidfunc,
    oldupiidfunc,
    onboardapi,
    warsoftapi,
    property_update,
    logo_img
  } = useContext(PropertyContext);

  const { TextArea } = Input;

  const [checkintime, setcheckintime] = useState("12");
  const [chktimeupdt, setchktimeupdt] = useState("");
  const [chkouttimeupdt, setchkouttimeupdt] = useState("");
  const [checkintype, setcheckintype] = useState(basic[0]?.checkintype || "AM");
  const [stategstnum, setstategstnum] = useState("");
  const [HBAddress, setHBAddress] = useState("");
  const [checkouttime, setcheckoutime] = useState(
    basic[0]?.checkouttime || "12"
  );

  const [checkouttype, setcheckouttype] = useState(
    basic[0]?.checkouttype || "AM"
  );
  const [gstnumber, setgstnumber] = useState(basic[0]?.gstnumber);
  const [propdescription, setpropdescription] = useState("");
  const [cancellationpolicy, setcancellationpolicy] = useState("");

  const [propertyname, setpropertyname] = useState(basic[0]?.propertyname);

  const [propertytype, setpropertytype] = useState(basic[0]?.propertytype || 0);
  const [Show, setShow] = useState(
    basic[0]?.propoption?.propoption === "Hotel" ? true : false
  );
  const [showserapart, setshowserapart] = useState(
    basic[0]?.propoption?.propoption === "Service Apartment" ? true : false
  );
  const [websiteAddress, setwebsiteAddress] = useState(
    basic[0]?.websiteAddress
  );
  const [legalname, setlegalname] = useState(basic[0]?.legalname);
  const [tradename, settradename] = useState(basic[0]?.tradename);
  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = useState(false);
  const [showchnlmanger, setshowchnlmanger] = useState(
    basic[0]?.showchnlmanger
  );
  //const [showchnlmanger, setshowchnlmanger] = useState((basic[0]?.ChannelManager!==""||basic[0]?.ChannelManager!==null||basic[0]?.ChannelManager!==undefined)?true:false);

  const [Channelmanager, setChannelmanager] = useState(
    basic[0]?.ChannelManager !== "" ? basic[0]?.ChannelManager : ""
  );
  const [ChannelManagerload, setChannelManagerload] = useState([]);

  const [Policies1, setPolicies1] = useState(basic[0]?.Policies1 || "1 day");
  const [Policies2, setPolicies2] = useState(
    basic[0]?.Policies2 || "the room night"
  );
  const [cancelpolicy1, setcancelpolicy1] = useState(
    "Day of arrival (6 pm)" || basic[0]?.cancelpolicy1
  );
  const [cancelpolicy2, setcancelpolicy2] = useState(
    "the room night" || basic[0]?.cancelpolicy2
  );
  const [gststatus, setgststatus] = useState("");
  // const [oneday, setOneday] = useState(basic[0]?.oneday);

  // const [onedaycheck, setonedaycheck] = useState(false);
  // const [errorchannelmanager, seterrorchannelmanager] = useState(false);
let errorchannelmanager = false;
  // const [oneday, setOneday] = useState(basic[0].checkintime==="24"?true:false);

  // const [disablechktime, setdisablechktime] = useState((oneday && oneday  === '24 hrs' || basic[0]?.oneday === 24) ? true : false);
  const [disablechktime, setdisablechktime] = useState(
    basic[0]?.oneday === 24 ? true : false
  );
  const [propertytypeId, setpropertytypeId] = useState("");
  const [proptertytypeload, setpropertytypeload] = useState([]);
  const [propertytypeoption, setpropertytypeoption] = useState(
    basic[0]?.propertytypeoption
  );
  const [channelmanageroption, setchannnelmanageroption] = useState(
    basic[0]?.channelmanageroption
  );
  //const [channelmanageroption, setchannnelmanageroption] = useState( basic[0]?.Channelmanager !== "" ? "Yes":"No" || basic[0]?.Channelmanager === null ? "Yes":"No");
  const [otherstext, setotherstext] = useState(basic[0]?.otherstext);
  const [serviceApp, setServiceApp] = useState(
    basic[0]?.serviceApp || "luxury Apartment"
  );
  const [errormessagearr, setmessagearr] = useState([]);
  const propnameRef = useRef(null);
  const propdescref = useRef(null);
  const [opengsterror, setopengsterror] = useState(false);
  const [gsterrofmsg, setgsterrofmsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validwebsite, setvalidwebsite] = useState(true);
  const [chnlmanagerid, setchnlmanagerïd] = useState();

  const [selectchlmger, setselectchlmger] = useState(
    basic[0]?.ChannelManager !== "" ? true : false
  );
  const [cancelpolicyload1, setcancelpolicyload1] = useState([]);
  const [cancelpolicyload2, setcancelpolicyload2] = useState([]);
  const [channelmanagerauto, setchannelmanagerauto] = useState(basic[0]?.Channelmanager);
  let websiteerror = true;

  const [check24, setcheck24] = useState(
    basic[0]?.checkintime === "24" ? true : false
  );

  // let channelmanagerothersload=[];

  const [textBox, setTextBox] = useState(false);

  const [checked, setChecked] = useState(basic[0]?.checked);
  const [animationCountshow, setAnimationCountshow] = useState(false);
  const [animationCount, setAnimationCount] = useState(0);
  const intervalRef = useRef(null);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  }

  const { id } = useParams();

  useEffect(() => {
    // Function to be executed every minute
    intervalRef.current = setInterval(() => {
      // Your logic here: Update state, call a function, etc.
      setAnimationCountshow(prevAnimationCount => !prevAnimationCount); // Increment count every minute
      setAnimationCount(prevCount => prevCount + 1);
    }, 2000); // 60000 milliseconds = 1 minute

    // Cleanup function to clear the interval when component unmounts
    return () => {
      clearInterval(intervalRef.current);
     // setAnimationCount(0);
    }
  }, []); 

  useEffect(() => {
    // When the animationCount reaches 2, clear the interval
    if (animationCount === 4) {
      clearInterval(intervalRef.current);
      setAnimationCountshow(prevAnimationCount => !prevAnimationCount);
      setAnimationCount(0);
    }
  }, [animationCount]); 


  useEffect(() => {
    if(basic[0]?.propid === undefined){
      document.querySelector("body").classList.add("propid");
}
   
    if (id && id.length > 30 && basic[0]?.propertyname !== "") {
      setIsLoading(true);
      idforUpdationfunc(id);
      const data = {
        rowid: `${id}`,
      };
      axios

        .post(`${property_update}/propeditselect/`, data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if(res.data[0][0]){
            document.querySelector("body").classList.remove("propid");
          propertyaggreidfunc([
            {
              AgreementId: res.data[4][0]?.AgreementId || '',
              propid: res.data[0][0]?.Id || '',
            },
          ]);
          let basicdata = [
            {
              propid: res.data[0][0]?.Id,
              propertyname: res.data[0][0]?.PropertyName,
              propertytype:
                (parseInt(res.data[0][0]?.PropertyType) > 1 &&
                  parseInt(res.data[0][0]?.PropertyType) < 7) === true
                  ? parseInt(res.data[0][0]?.PropertyType) - 1
                  : parseInt(res.data[0][0]?.PropertyType),
                  propaddress : res.data[0][0]?.Propertaddress,
              checkintime:
                parseInt(res.data[0][0]?.CheckIn) < 10
                  ? 0 + `${res.data[0][0]?.CheckIn}`
                  : `${res.data[0][0]?.CheckIn}`,
              checkouttime:
                parseInt(res.data[0][0]?.CheckOut) < 10
                  ? 0 + `${res.data[0][0]?.CheckOut}`
                  : `${res.data[0][0]?.CheckOut}`,
              checkouttypeampm: res.data[0][0]?.CheckOutType,
              checkintypeampm: res.data[0][0]?.CheckInType,
              propdescription: res.data[0][0]?.PropertDescription,
              gstnumber:
                res.data[0][0]?.GSTNumber?.length > 10
                  ? res.data[0][0]?.GSTNumber
                  : "",
              legalname:
                res.data[0][0]?.GST_LegalName || res.data[0][0]?.LegalName,
              Policies1: res.data[0][0]?.cancelpolicy1,
              Policies2: res.data[0][0]?.cancelpolicy2,
              cancelpolicy1: res.data[0][0]?.cancelpolicy1,
              cancelpolicy2: res.data[0][0]?.cancelpolicy2,
              websiteAddress: res.data[0][0]?.WebsiteAddress,
              // channelmanageroption:  ((res.data[0][0]?.Chnlmanager !== null) === true && (res.data[0][0]?.chnlmanagerid === 0) === true)? "Yes" :
              // ((res.data[0][0]?.Chnlmanager === '') === true &&  (res.data[0][0]?.chnlmanagerid === 0) === true) ? "Yes" :
              // ((res.data[0][0]?.Chnlmanager !== '' && res.data[0][0]?.Chnlmanager !== null) === true &&  (res.data[0][0]?.chnlmanagerid !== 0 && res.data[0][0]?.chnlmanagerid !== null) === true) ? 'Yes' : 'No',
              channelmanageroption:
                res.data[0][0]?.Chnlmanager !== "Others" &&
                res.data[0][0]?.Chnlmanager !== "" &&
                res.data[0][0]?.Chnlmanager !== "N/A" &&
                res.data[0][0]?.Chnlmanager !== null &&
                res.data[0][0]?.Chnlmanager !== undefined
                  ? "Yes"
                  : "No",
              Channelmanager:
                res.data[0][0]?.Chnlmanager !== "Others" &&
                res.data[0][0]?.Chnlmanager !== "" &&
                res.data[0][0]?.Chnlmanager !== "N/A" &&
                res.data[0][0]?.Chnlmanager !== null &&
                res.data[0][0]?.Chnlmanager !== undefined
                  ? res.data[0][0]?.Chnlmanager
                  : "",
              tradename: res.data[0][0]?.gst_tradename,
              gststatus: res.data[0][0]?.GST_Status,
              LegalAddress: res.data[0][0]?.LegalAddress,
              oneday: res.data[0][0]?.CheckIn === 24 ? 24 : false,
              propertytypeoption:
                (res.data[5][0]?.propoption === "Serviced Apartment" ||
                  res.data[5][0]?.propoption === "Service Apartment") ===
                  true && "Service Apartment",
              propoption:
                res.data[5][0]?.propoption === "Serviced Apartment"
                  ? {
                      propoption: "Service Apartment",
                      PropertyType: res.data[5][0]?.PropertyType,
                    }
                  : res.data[5][0],
              serviceApp:
                ((res.data[5][0]?.propoption !== "Hotel") === true &&
                  (res.data[5][0]?.propoption !== "Resort") === true &&
                  (res.data[5][0]?.propoption !== "PG/Hostel") === true) ===
                  true && res.data[5][0]?.PropertyType,
              chnlmanagerid: res.data[0][0]?.Chnlmanagerid,
              checked: res.data[0][0]?.CheckIn == 24,
            },
          ];

          addToBasic(basicdata);

          if (res.data[0][0]?.CheckIn === 24) {
            setdisablechktime(true);

            // setcheck24(basic[0]?.oneday === 24 ? true : false)
          }

          basic_GST_verified(
            (res.data[0][0]?.GSTNumber !== "" &&
              res.data[0][0]?.GSTNumber?.length > 10) === true && "Verified"
          );
          basic_Verifiedbut(
            (res.data[0][0]?.GSTNumber !== "" &&
              res.data[0][0]?.GSTNumber?.length > 10) === true && true
          );
          let lat_long = res.data[0][0]?.LatitudeLongitude.split(",");
          let lat_longobj = {
            lat: lat_long[0],
            lng: lat_long[1],
          };

          const statenameselect = {
            StateId: res.data[0][0]?.StateId,
          };
          axios
            .post(`${onboardapi}/SelectStatename`, statenameselect)
            .then((res) => {
              setstategstnum(res.data.data[0]?.GSTNumber);
              setHBAddress(res.data.data[0]?.HBAddress);
            })
            .catch((err) => {
              console.log();
            });
          let locrr = [
            {
              Addrsline1: res.data[0][0]?.PropertyAddressline1,
              Addrsline2: res.data[0][0]?.PropertyAddressline2,
              Statename: res.data[0][0]?.State,
              Cityname: res.data[0][0]?.City,
              localityname: res.data[0][0]?.Localityarea,
              StateId: res.data[0][0]?.StateId,
              CityId: res.data[0][0]?.CityId,
              localityId: res.data[0][0]?.LocalityId,
              Pincode: res.data[0][0]?.Postal,
              Directions: res.data[0][0]?.Directions,
              latlng: lat_longobj,
              stategstnum: stategstnum,
              HBAddress: HBAddress,
            },
          ];

          addToLocations(locrr);

          let safetyAmenities = [
            res.data[0][0]?.Womensafety === true
              ? "Women safety and friendly"
              : "",
            res.data[0][0]?.CCTVsurveillance === true
              ? "CCTV surveillance"
              : "",
            res.data[0][0]?.Contactlesschkinchkout === true
              ? "Contactless check-in / check-out"
              : "",
            res.data[0][0]?.Securityguard === true ? "Security guard" : "",
            res.data[0][0]?.Fireextinguisher === true
              ? "Fire extinguisher"
              : "",
            res.data[0][0]?.Emergencyexit === true ? "Emergency exit" : "",
          ];
          let CleaninessAmenities = [
            res.data[0][0]?.Hygienictowels === true
              ? "Hygienic towels and blanket"
              : "",
            res.data[0][0]?.Freefacemask === true ? "Free face masks" : "",
            res.data[0][0]?.Freeofpests === true ? "Free of pests" : "",
            res.data[0][0]?.Cleanedbyteam === true
              ? "Cleaned by professional cleaning team"
              : "",
            res.data[0][0]?.Handsanitizer === true ? "Hand sanitizer" : "",
            res.data[0][0]?.Waterandelectricity === true
              ? "Water and electricity"
              : "",
          ];
          let otherinformation = [
            res.data[0][0]?.ServicesLaundry === true ? "Laundry services" : "",
            res.data[0][0]?.ServicesCyberCafe === true
              ? "Cyber cafe / Wifi"
              : "",
            res.data[0][0]?.ServicesConfHall === true ? "Conference hall" : "",
            res.data[0][0]?.ServicesRestaurant === true
              ? "Restaurtant services"
              : "",
            res.data[0][0]?.ServicesSwimPool === true
              ? "Swimming pool services"
              : "",
            res.data[0][0]?.ServicesGym === true ? "Gym services" : "",
            res.data[0][0]?.TourAssistance === true ? "Tour assistance" : "",
            res.data[0][0]?.PetFriendly === true ? "Pet friendly" : "",
            res.data[0][0]?.Parking === true ? "Parking" : "",
          ];
          addToFacilities([
            {
              safetyAmenities: safetyAmenities?.filter((item) => item !== ""),
              CleaninessAmenities: CleaninessAmenities?.filter(
                (item) => item !== ""
              ),
              otherinformation: otherinformation?.filter((item) => item !== ""),
            },
          ]);

          let newcontactDetail = res.data[4]?.map((item, i) => ({
            key: item?.Id,
            id: parseInt(item?.Id),
            RoomType: item?.RoomType,
            ValidityFrom: formatDate(item?.FromDt),
            ValidityTo: formatDate(item?.ToDt),
            SingleRackRate: item?.Single,
            SingleDiscountRate: item?.RackSingle,
            DoubleRackRate: item?.RDouble,
            DoubleDiscountRate: item?.RackDouble,
            MealPlan: item?.MealPlan,
            Tacper:
              item?.TACPer === null ? 0 : parseFloat(item?.TACPer).toFixed(2),
            Inclusions: item?.Facility.split(","),
            // RoomImagename:
            //   item?.RoomImagePath.split("/")[4].split("-")[1] +
            //   i +
            //   "." +
            //   item?.RoomImagePath.split(".").pop(),
            RoomImagename:
              (item?.RoomImagename === undefined ||
                item?.RoomImagename === "undefined" ||
                item?.RoomImagename === null ||
                item?.RoomImagename === "null") === true
                ? ""
                : item?.RoomImagename,

            Roomimagepath: item?.RoomImagePath.replace("http:", "https:"),
            trafficoption: item?.Inclusive === true ? "Yes" : "No",
            TACinclusive:item?.TACinclusive,
            Temp:item?.Temp,
            Conference:item?.Conference,
            Visible:item?.Visible,
            isDelete : false,
          }));
          addToRoomDetails({
            btccreditperiod:
              res.data[0][0]?.CreditPeriod === null
                ? 0
                : res.data[0][0]?.CreditPeriod,
            btccreditperiodoption:
              res.data[0][0]?.CreditPeriod === undefined ||
              res.data[0][0]?.CreditPeriod === null || res.data[0][0]?.CreditPeriod === 0
                ? "No"
                : "Yes",
            roomdetarr: newcontactDetail,
          });
          let propimg = res.data[1].map((item, i) => ({
            uid: parseInt(item?.Id),
            id: parseInt(item?.Id),
            name: item?.ImageName,
            status: "done",
            url: item?.ImageLocation.replace("http:", "https:"),
            ImageLocation: item?.ImageLocation.replace("http:", "https:"),
            ImageName: item?.ImageName,
            imagepath1: item?.ImageLocation.replace("http:", "https:"),
            imagename1: item?.ImageName,
          }));

          addToPropImages(propimg);

          let contactarr = res.data[3]?.map((item) => ({
            key: item?.Id,
            id: parseInt(item?.Id),
            contactType: item?.ContactType,
            contactName: item?.ContactName,
            Email: item?.Email,
            mobileno: item?.ContactNo,
            landlineno: item?.LandlineNo,
          }));

          let Propertyowners = [
            {
              Payeename: res.data[2][0]?.PayeeName,
              Bankname: res.data[2][0]?.Bank,
              Accountnumber: res.data[2][0]?.AccountNumber,
              IFSCcode: res.data[2][0]?.IFSC,
              AccountType: res.data[2][0]?.AccountType,
              branchAddress: res.data[2][0]?.BranchAddress,
              checkleafpath:res.data[2][0]?.Chkleafimg ? [
                {
                  uid: "1",
                  name:res.data[2][0]?.ImageName !== null ? res.data[2][0]?.ImageName : '',
                  status: "done",
                  url: res.data[2][0]?.Chkleafimg !== null ? res.data[2][0]?.Chkleafimg.replace("http:", "https:") : '',
                  ImageLocation: res.data[2][0]?.Chkleafimg !== null ? res.data[2][0]?.Chkleafimg.replace(
                    "http:",
                    "https:"
                  ):"",
                  ImageName: res.data[2][0]?.ImageName !== null ? res.data[2][0]?.ImageName : '',
                },
              ] : [],
              UpiId: res.data[2][0]?.UPIId,
              Upiname: res.data[2][0]?.UPIName,
              Upimobilenumber: res.data[2][0]?.UPIMobileNumber,
              // chkleafimagepath: [
              //   {
              //     uid: "1",
              //     name: res.data[1][0]?.ImageName,
              //     status: "done",
              //     url: res.data[1][0]?.ImageLocation,
              //     ImageLocation: res.data[1][0]?.ImageLocation,
              //     ImageName: res.data[1][0]?.ImageName,
              //   },
              // ],
            },
          ];
          oldupiidfunc({
            upiid: res.data[2][0]?.UPIId,
            ownerid: res.data[2][0]?.Id,
          });
          // profile_acVerifiedbut((res.data[2][0]?.AccountNumber?.length > 5 && res.data[2][0]?.IFSC?.length > 5) ? true :false);
          profile_acVerifiedbut(
            res.data[0][0]?.AccountVerifiedFlg === false ||
              res.data[0][0]?.AccountVerifiedFlg === null
              ? false
              : true
          );
          profile_upi_Verifiedbut(
            res.data[2][0]?.UPIId?.length > 5 ? true : false
          );
          addToProfile([
            { Propertyowners: Propertyowners, contactarr: contactarr },
          ]);

          bankNamefunc({ account_name: res.data[2][0]?.PayeeName });
          upiNamefunc({ vpa_name: res.data[2][0]?.UPIName });
          //  setPayeename(res.data[2][0]?.UPIName)
          setIsLoading(false);
        }
        else {
          setIsLoading(false);
          setOpen(true);
          setmessagearr(["Your property is not active please contact HB"]);
        }
        })
        .catch((error) => console.log());
    }
  }, []);

  useEffect(() => {
    if (basic[0]?.propertyname !== "") {
      setpropertyname(basic[0]?.propertyname);
      setpropertytype(basic[0]?.propertytype);
      setcheckintime(
        (basic[0]?.checkintime === "24" && "12") || basic[0]?.checkintime
      );
      setcheckoutime(
        (basic[0]?.checkouttime === "24" && "12") || basic[0]?.checkouttime
      );
      setcheckouttype(
        (basic[0]?.checkintime === "24" && "AM") || basic[0]?.checkouttypeampm
      );
      setcheckintype(
        (basic[0]?.checkintime === "24" && "AM") || basic[0]?.checkintypeampm
      );
      setpropdescription(basic[0]?.propdescription);
      setgstnumber(basic[0]?.gstnumber);
      setlegalname(basic[0]?.legalname);
      setPolicies1(
        basic[0]?.Policies1 === "Day of arrival (6 pm)"
          ? "6 pm"
          : basic[0]?.Policies1
      );
      setcancelpolicy1(basic[0]?.Policies1);
      setcancelpolicy2(basic[0]?.cancelpolicy2);
      setPolicies2(basic[0]?.cancelpolicy2);
      setwebsiteAddress(basic[0]?.websiteAddress);
      // setotherstext(basic[0]?.Channelmanager);
      setselectchlmger(basic[0]?.Channelmanager);
      setpropertytypeoption(basic[0]?.propoption?.propoption);
      setServiceApp(basic[0]?.serviceApp);
      setChecked(basic[0]?.checked);
    }
  }, [basic[0]]);

  // for updation end

  let numincrement = 1;

  const onChangeradio = (e) => {
    setpropertytype(0);
    // setValue(e.target.value);
    setpropertytypeoption(e.target.value);
    changescheckingfunc("Property type updated");
    setShow(false);
    if (e.target.value == "Hotel") {
      setShow(true);
      setshowserapart(false);
    }
    if (e.target.value === "Service Apartment") {
      setShow(false);
      setshowserapart(true);
    }
    if (e.target.value === "PG/Hostel") {
      setShow(false);
      setshowserapart(false);
      setpropertytype("15");
    }
    if (e.target.value === "Resort") {
      setShow(false);
      setshowserapart(false);
      setpropertytype("16");
    }
  };

  const onChangeradiochnlmanager = (e) => {
    setchannnelmanageroption(e.target.value);
    //setchannelmanagerauto("");
    setTextBox(false)

    if (e.target.value == "Yes") {
      setshowchnlmanger(true);
      setotherstext("");
    }
    if (e.target.value == "No") {
      setshowchnlmanger(false);
      setTextBox(false);
      setotherstext("");
    }
    changescheckingfunc("Channel manager updated");
  };

  const navigate = useNavigate();

  const showModal = () => {
    setmessagearr([]);



    let prop_Name = document.querySelector("#prop_Name");
    let prop_type = document.querySelector("#prop_type");
    let check_time = document.querySelector("#check_time");
    let checkout_time = document.querySelector("#checkout_time");
    let cheoneday = document.querySelector("#cheoneday");
    let pro_descrip = document.querySelector("#pro_descrip");
    let gst_number = document.querySelector("#gst_number");
    let c_manager_group = document.querySelector("#c_manager_group");
    let channel_manager_text = document.querySelector(".channel_manager_text");
    let web_site = document.querySelector("#web_site");
    let setcancelation = document.querySelector(".setcancelation");
    let remove_error = document.querySelectorAll(".border_red");
    let channel_manager_select = document.querySelector(
      ".channel_manager_select > div > div > span > input"
      
    );
    let website = document.querySelectorAll("#web_site");

    remove_error.forEach((item) => item.classList.remove("border_red"));

    if (prop_Name?.value === "") {
      // setOpen(true)
      setmessagearr((pre) => [...pre, "* Please enter the property name"]);
      prop_Name.classList.add("border_red");
    }

    if (prop_type?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the property type"]);
      prop_type.classList.add("border_red");
    }
    if (cheoneday.checked === false) {
      if (check_time?.value === "" || checkout_time?.value === "") {
        let date_in = document.querySelector(".date_in > div");

        setmessagearr((pre) => [
          ...pre,
          "* Please enter the check-in & check-out time",
        ]);
        if (check_time?.value === "") {
          date_in.classList.add("border_red");
        }
        if (checkout_time?.value === "") {
          let date_out = document.querySelector(".date_out > div");

          date_out.classList.add("border_red");
        }
      }
    }
    let select_ser_app = document.querySelector(
      ".service-apt > div.ant-card > div > div > div.ant-select-selector > span.ant-select-selection-item"
    );

    if (propertytypeoption && propertytypeoption === "Service Apartment") {
      if (
        select_ser_app?.innerHTML === "Select Service Apartment" ||
        select_ser_app?.innerHTML === "" ||
        select_ser_app?.innerHTML === undefined
      ) {
        setmessagearr((pre) => [...pre, "* Please select service apartment"]);
        select_ser_app?.classList.add("border_red");
      }
    }
    if (pro_descrip?.value === "") {
      setmessagearr((pre) => [
        ...pre,
        "* Please enter the property description",
      ]);
      pro_descrip.classList.add("border_red");
    }
    if (gst_number?.value === "") {
      setmessagearr((pre) => [...pre, `* Please enter the GSTIN`]);
      gst_number.classList.add("border_red");
    } else {
      if (basicverify === false) {
        setmessagearr((pre) => [...pre, "* Please verify the GSTIN"]);
      }
    }
    if (propertytypeoption === "Hotel") {
      if (propertytype < 1) {
        setmessagearr((pre) => [...pre, "* Please give your home a rating"]);
      }

      pro_descrip.classList.add("border_red");
    }

    // if (channelmanageroption === "Yes") {

    //   if (channel_manager_select === "" || channel_manager_select === null ) {

    //       setmessagearr((pre) => [
    //         ...pre,
    //         "* Please select the channel manager",
    //       ]);

    //   }
    //  }
    // if (channelmanageroption === "Yes") {
   
    //   if (Channelmanager === "" || Channelmanager === undefined) {
    //     let channel_manager_select = document.querySelector(
    //       ".channel_manager_select > div > div"
    //     );
    //     if (channel_manager_select) {
    //       setmessagearr((pre) => [
    //         ...pre,
    //         "* Please select the channel manager",
    //       ]);
    //       channel_manager_select.classList.add("border_red");
    //     }
    //   } else if (Channelmanager === "Others") {
    //     let channel_manager_text = document.querySelector(
    //       ".channel_manager_text"
    //     );
    //     if (channel_manager_text.value === "") {
    //       setmessagearr((pre) => [
    //         ...pre,
    //         "* Please enter the channel manager",
    //       ]);
    //       channel_manager_text.classList.add("border_red");
    //     }
    //   }
    // } else {
    // }
let chanl_mng = document.querySelector("#c_manager_group .ant-radio-checked input")?.value;
    if (chanl_mng === "Yes") {
     let channel_manager_select = document.querySelector(
        ".channel_manager_select > div > div span input"
      );

      if (channel_manager_select?.value === '' || channel_manager_select?.value === undefined) {
        

        errorchannelmanager = true;
          setmessagearr((pre) => [
            ...pre,
            "* Please select the channel manager",
          ]);
          channel_manager_select.classList.add("border_red");
        
      } else if (channel_manager_select?.value  === "Others") {
        errorchannelmanager = false;

      

        if (channel_manager_text?.value === "") {
          setmessagearr((pre) => [
            ...pre,
            "* Please enter the channel manager",
          ]);
          
        }
        else {

         
        }
       
      }
    } else {
      errorchannelmanager = false;
    }

   

  

    let channelmanaer = document.querySelector(
      ".ant-select-selection-placeholder"
    );

    if (web_site?.value !== "") {
      function isValidURL(string) {
        var res = string.match(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
        );
        return res !== null;
      }

      websiteerror = isValidURL(web_site?.value);

      if (websiteerror !== true) {
        setmessagearr((pre) => [
          ...pre,
          "* Please enter a valid website address",
          `(e.g. https://www.${basic[0]?.propertyname?.replace(/\s+/g, '')}.com)`,
        ]);
      }
    }

     if (channel_manager_select?.value !== '' && channel_manager_select?.value  !== "Others" && chanl_mng === "Yes") {
      let check_channelvalue = ChannelManagerload?.filter((item) => item?.Channelmanager.toLowerCase() === channel_manager_select?.value.toLowerCase())
      if(check_channelvalue?.length === 0){
        setmessagearr((pre) => [
          ...pre,
          "* Please select the channel manager from the list",
        ]);

       // seterrorchannelmanager(true);
        errorchannelmanager = true;

      }

    }


    if (
      cheoneday.checked === false
        ? prop_Name?.value === "" ||
          prop_type?.value === "" ||
          check_time?.value === "" ||
          checkout_time?.value === "" ||
          pro_descrip?.value === "" ||
          gst_number?.value === "" ||
          c_manager_group?.value === "" ||
          channel_manager_text?.value === "" ||
          (channel_manager_select && channel_manager_select === "") ||
          (channel_manager_select && channel_manager_select === null) ||
          channelmanaer?.innerHTML === "Select Channel Manager" ||
          basicverify === false ||
          validwebsite === false ||
          (propertytypeoption === "Hotel" && propertytype < 1) ||
          select_ser_app?.innerHTML === "Select Service Apartment" ||
          select_ser_app?.innerHTML === "" ||
          websiteerror === false ||
          errorchannelmanager===true
        : prop_Name?.value === "" ||
          prop_type?.value === "" ||
          pro_descrip?.value === "" ||
          gst_number?.value === "" ||
          c_manager_group?.value === "" ||
          channel_manager_text?.value === "" ||
          (channel_manager_select && channel_manager_select === "") ||
          (channel_manager_select && channel_manager_select === null) ||
          channelmanaer?.innerHTML === "Select Channel Manager" ||
          basicverify === false ||
          validwebsite === false ||
          (propertytypeoption === "Hotel" && propertytype < 1) ||
          select_ser_app?.innerHTML === "Select Service Apartment" ||
          select_ser_app?.innerHTML === "" ||
          websiteerror === false || 
          errorchannelmanager===true
    ) {
      setOpen(true);
    } else {
      setmessagearr([]);
     //123 setIsLoading(true);
      let proparr = [
        {
          propertyname: multispacetrim(propertyname) || basic[0]?.propertyname,
          // propertytype: propertytype || basic[0]?.propertytype,
          propertytype: propertytype || basic[0]?.propertytype,
          propertytypeoption:
            propertytypeoption || basic[0]?.propertytypeoption,
          checkintime:
            checked === true ? 24 : checkintime || basic[0]?.checkintime,
          //checkintype: checkintype || basic[0]?.checkintype,
          checkouttime:
            checked === true ? 24 : checkouttime || basic[0]?.checkouttime,
          // checkintype: chktimeupdt || basic[0]?.checkintype,
          checkouttype: chkouttimeupdt || basic[0]?.checkouttype,
          gstnumber: gstnumber || basic[0]?.gstnumber,
          propdescription:
            multispacetrim(propdescription) || basic[0]?.propdescription,
          cancellationpolicy: setcancelation?.innerHTML,
          websiteAddress:
            multispacetrim(websiteAddress) || basic[0]?.websiteAddress || "",
            Channelmanager:
            channelmanageroption === "No"
              ? ""
              : Channelmanager || basic[0]?.Channelmanager,
          gststatus: gststatus || basic[0]?.gststatus,
          legalname: legalname || basic[0]?.legalname,
          tradename: tradename || basic[0]?.tradename || "",
          channelmanageroption:
            channelmanageroption || basic[0]?.channelmanageroption,
          otherstext:channelmanageroption === "No"
          ? ""
          : multispacetrim(channel_manager_text?.value),
          Policies1: Policies1 || basic[0]?.Policies1,
          Policies2: Policies2 || basic[0]?.Policies2,
          cancelpolicy1: cancelpolicy1 || basic[0]?.cancelpolicy1 || "1 day",
          cancalpolicy2:
            cancelpolicy2 || basic[0]?.cancelpolicy2 || "the room night",
          // oneday: oneday || basic[0]?.oneday,
          serviceApp: serviceApp || basic[0]?.serviceApp,
          checkouttypeampm:
            checked === true ? "Hrs" : checkouttype || basic[0]?.checkouttype,
          checkintypeampm:
            checked === true ? "Hrs" : checkintype || basic[0]?.checkintype,
            chnlmanagerid:
            channelmanageroption === "No"
              ? 0
              : chnlmanagerid || basic[0]?.chnlmanagerid,
          basic_Verifiedbut: basic_Verifiedbut,
          propid: basic[0]?.propid,
          LegalAddress: basic[0]?.LegalAddress,
          checked: checked,
          propaddress: basic[0]?.propaddress,
        },
      ];
      //localStorage.setItem("Propbasics", JSON.stringify(proparr));
      addToBasic(proparr);

   return navigate("/location");


      // if (
      //   Channelmanager === "Others" ||
      //   basic[0]?.Channelmanager === "Others"
      // ) {
      //   let data = {
      //     Channelmanager: multispacetrim(channel_manager_text?.value),
      //   };
      //   const responsepropertytype = axios
      //     .post(`${onboardapi}/Insertchannelmanager`, data)
      //     .then((res) => {
      //       //  setmessagearr([]);
     
      //       if (res.data[0].msg === "ALREADY EXISTS") {
      //         setOpen(true);
      //         setmessagearr((pre) => [
      //           ...pre,
      //           "Channel manager already exists",
      //         ]);
      //       } else {
      //            if(basic[0]?.propid !== undefined){
      //         return navigate("/location");
      //            }
              
      //         window.scrollTo(0, 0);
      //       }

      //       // setpropertytypeId(res.data[0].Id);
      //     });
      // }
      //  setIsLoading(true);
      if(basic[0]?.propid !== undefined){
       return navigate("/location");
}
    
      window.scrollTo(0, 0);
    }
  };

  let options = [];
  useEffect(() => {
    window.scrollTo(0, 0);
    // clear the time when 24 hrs selected
    if (basic[0]?.oneday === "24 Hrs") {
      setcheckintime("");
      setcheckintype("");
      setcheckouttype("");
      setcheckoutime("");
    }

    setcancellationpolicy(
      `The guest must cancel by ${
        Policies1 !== "Day of arrival (6 pm)" ? Policies1 : "6:00 PM"
      } on the day of arrival or pay 100% of the price  ${
        Policies2 !== "" ? Policies2 : "the room night"
      }`
    );
    const items = JSON.parse(localStorage.getItem("Propbasics"));
    if (items) {
      setpropertyname(items[0].propertyname);
    }

    const responsepropertytype = axios
      .post(`${onboardapi}/Propertytype`)
      .then((res) => {
        setpropertytypeload(res.data);
      })
      .catch((err) => {
       
      });
    const responsechannelmanger = axios
      .post(`${onboardapi}/Selectchannelmanager`)
      .then((res) => {
        setChannelManagerload(res.data);
      })
      .catch((err) => {
        console.log();
      });

    // //set default property type
    if (basic[0]?.propertytypeoption) {
      if (basic[0]?.propertytypeoption === "Hotel") {
        setShow(true);
      } else {
        setShow(false);
      }
    }
    axios
      .post(`${property_update}/selectcancelpolicy`)
      .then((res) => {
        setcancelpolicyload1(res.data[0]);
        setcancelpolicyload2(res.data[1]);
      })
      .catch((err) => {
        console.log();
      });
  }, []);

  const onChangechecktime = (e) => {
    // setOneday(!oneday)

    if (e.target.checked == true) {
      // setcheck24(true)
      setdisablechktime(true);
      setcheckintime("12");
      setcheckintype("AM");
      setcheckouttype("AM");
      setcheckoutime("12");
      setchktimeupdt("");
      setchkouttimeupdt("");
      setChecked(true);
    } else {
      // setdisablechktime(false);
      // setOneday("0 hrs");
      // setcheck24(true)

      setdisablechktime(false);
      setcheckintime("12");
      setcheckintype("AM");
      setcheckouttype("AM");
      setcheckoutime("12");
      setChecked(false);
    }
    // if (e.target.checked == false) {

    // } else {
    // }
    changescheckingfunc("Check-in & check-out time updated");
  };

  function gstnumverification() {
    if (gstnumber === "" || gstnumber === undefined) {
      setopengsterror(true);
      setmessagearr(["* Please enter the GSTIN"]);
      setOpen(true);
    } else {
      if (gstnumber?.length < 15) {
        setmessagearr(["* GSTIN must be 15 digits"]);
        setOpen(true);
      } else {
        setIsLoading(true);
      }
    }
    const body = {
      GSTNo: gstnumber,
    };
    let statename;
    const response = axios
      .post(`${property_update}/GSTNoValidatetoken`, body)
      //.post(`${property_update}/GSTNoValidatetoken`, body)
      .then((res) => {
        //set state id
       
        axios
          .post(`${onboardapi}/selectstate`)
          .then((resstate) => {
            let state_id = resstate.data.data.filter(
              (item) =>
                item.StateName === res.data?.MESSAGE?.result?.pradr?.addr?.stcd
            );

            addToLocations([
              {
                ...locationValue[0],
                Statename: res.data?.MESSAGE?.result?.pradr?.addr?.stcd,
                StateId: state_id[0]?.Id,
              },
            ]);
          })
          .catch((error) => console.log());
        //set state id

        if (res.data.MESSAGE.success === true) {
          setgststatus(res.data.MESSAGE.result.sts);
          setlegalname(res.data.MESSAGE?.result?.lgnm);
          settradename(res.data.MESSAGE?.result?.tradeNam);
          basic_GST_verified(res.data.MESSAGE.result);
          // setshowverifysymb(true);
          // setshowverifybtn(false);
          basic_Verifiedbut(true);
          setgsterrofmsg(false);
        } else {
          setopengsterror(true);
          setgsterrofmsg(true);
          if (gstnumber?.length < 15 && gstnumber?.length > 0) {
            setmessagearr(["* GSTIN must be 15 digits"]);
          } else {
            let gstin = document.querySelector("#gst_number");

            setmessagearr([
              gstin?.value.length === 0
                ? "* Please enter the GSTIN"
                : "* Invalid GSTIN",
              "* It is your responsibility to review local laws and taxes. Be responsibile in paying any applicable taxes (GSTIN) on all bookings.",
            ]);
          }
          setOpen(true);
        }
        setIsLoading(false);
      })
      .catch((err) => {
       
        setIsLoading(false);
      });
  }

  const optionscancelpolicy1 = cancelpolicyload1?.map((element, i) => {
    return {
      key: i,
      value: element.cancellationpolicy1,
      label: element.cancellationpolicy1,
    };
  });

  const optionscancelpolicy2 = cancelpolicyload2?.map((element, i) => {
    return {
      key: i,
      value: element.cancellationpolicy2,
      label: element.cancellationpolicy2,
    };
  });
  const handleCancel = () => {
    setOpen(false);
  };
  const handleCancelerror = () => {
    setopengsterror(false);
  };

  const handleChangeselect = (value) => {
    changescheckingfunc("Property type updated");
    setServiceApp(value);
    let data = {
      propertytype: value,
    };
    const responsepropertytype = axios
      .post(`${onboardapi}/PropertytypeId`, data)
      .then((res) => {
        setpropertytypeId(res.data[0].Id);
        setpropertytype(res.data[0].Id);
      });
  };

  //add the Others text to the last of the list
  let ch_ch = ChannelManagerload.map((element, i) =>
    element.Channelmanager === "Others"
      ? ChannelManagerload.splice(i, 1)
      : {
          value: element.Channelmanager,
          label: element.Channelmanager,
        }
  );
  ch_ch.push({
    value: "Others",
    label: "Others",
  });
  useEffect(() => {
    if (basic[0]?.oneday === 24) {
      setcheck24(true);
    } else {
      setcheck24(false);
    }
  }, [check24]);
  
  return ( 
    <>
      {isLoading && (
        <div className="loaderloading">
          <div className="example">
            <Spin />{" "}
            <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
              Loading ...
            </span>
          </div>
        </div>
      )}
      <nav className="row">
        <a className="navbar-brand col-md-2 text-center mobile-show" href="#">
          <img
            className="3"
            src={logo_img}
            alt=""
            width={"120px"}
            height={"85px"}
          />
        </a>
        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon">fsfds</span>
      </button> */}
        <span className="text-center col-md-12 d-flex justify-content-center align-items-center">
          <h3>
            <b>Property Onboarding</b>
          </h3>
        </span>
      </nav>
      <form>
        <div className="container-fluid g-padding">
          <div className="row">
            <div
              className="col-md-2 no-border-l h100"
              style={{ background: "#fff", borderLeft: "none" }}
            >
              <Card className="col-md-12 h-100 side-menu">
                <Steps
                  size="small"
                  className="mt-md-5 cursor-not-allowed mobile-hide"
                  direction="vertical"
                  current={0}
                >
                  <Steps.Step title={<NavLink to="/">Basics</NavLink>} />
                  <Steps.Step
                    title={<NavLink to="/location">Location</NavLink>}
                  />
                  <Steps.Step
                    title={<NavLink to="/facilities">Facilities</NavLink>}
                  />
                  <Steps.Step
                    title={<NavLink to="/roomdet">Room details</NavLink>}
                  />
                  <Steps.Step title={<NavLink to="/photos">Photos</NavLink>} />
                  <Steps.Step
                    title={<NavLink to="/Profile">Contact</NavLink>}
                  />
                  <Steps.Step
                    title={<NavLink to="/Publish">Publish</NavLink>}
                  />
                </Steps>
                <a
                  className="navbar-brand col-md-2 text-center logo-display"
                  href="#"
                >
                  <img
                    className="logo_name"
                    src={logo_img}
                    alt=""
                    width="100%"
                  />
                </a>
                <p className="mt-sm-3 desk_browser" style={{fontSize: "12px"}}><span className="fw-bold" style={{fontSize: "14px",marginBottom:"10px"}}>Supported Browsers : </span><br/><span style={{margin:"0px",lineHeight:"10px"}}></span>1. Google Chrome,<br/>2. Microsoft Edge,<br/>3. Firefox.</p> 
              </Card>
            </div>
            <div
              className="col-md-10 bgcolor layout-ml"
              style={{ background: "rgb(227 245 255)" }}
            >
              <div className="row">
                <div className="col-sm-6 p-3">
                  <h3>Match your property to the right travelers.</h3>
                  <p>All information is required unless marked optional.</p>
                </div>
                <div className="col-sm-6 mt-2">
                  <img src={bagpic} alt="" height={"120px"} />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-3 ">
                  <h5>
                    Property Name&nbsp;<span className="m-red">*</span>
                  </h5>
                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <Input
                      placeholder="Property Name"
                      value={propertyname}
                      onChange={(event) => {
                        setpropertyname(
                          event.target.value.replaceAll("  ", " ")
                          //event.target.value.replace(/\s+/g, ' ')
                        );
                      }}
                      readOnly
                      ref={propnameRef}
                      name="property"
                      id="prop_Name"
                      onInput={propInput}
                      // onInputChange={onInputValueChange}
                      maxLength={100}
                    />
                  </Card>
                </div>
                <div className="col-sm-9 col-md-9 col-lg-6 mt-2 mt-sm-0 service-apt">
                  <h5>Select Property Type </h5>

                  <Radio.Group
                    onChange={onChangeradio}
                    value={propertytypeoption || basic[0]?.propertytypeoption}
                    className="col-sm-4 float-start"
                    id="prop_type"
                  >
                    <Radio value={"Hotel"} className="col-8">
                      <b>Hotel</b>
                    </Radio>
                    {/* <p className='d-inline'>Multi-unit accommodation building with shared facilities, restaurants, and services</p> */}
                    <Radio value={"Service Apartment"}>
                      <b>Service Apartment</b>
                    </Radio>
                    <Radio value={"PG/Hostel"} className="col-8">
                      <b>PG&nbsp;/&nbsp;Hostel</b>
                    </Radio>
                    <Radio value={"Resort"} className="col-8">
                      <b>Resort</b>
                    </Radio>
                    {/* <p className='d-inline'>Apartment unit with basic services like cleaning and front desk</p> */}
                  </Radio.Group>
                  {/* <div className="col-sm-3"> */}

                  {(Show ||
                    propertytypeoption === "Hotel" ||
                    (propertytypeoption !== "Service Apartment" &&
                      propertytypeoption !== "PG/Hostel" &&
                      propertytypeoption !== "Resort" &&
                      basic[0]?.propertytypeoption === "Hotel")) && (
                    <Card
                      className="mt-2 mt-sm-0 col-12 col-sm-8 float-start"
                      style={{
                        background: "#f4f4f4",
                        boxShadow: "rgb(207 202 202) 0px 0px 10px",
                      }}
                    >
                      <p>
                        Give your home a rating to help set expectations for
                        travelers stay&nbsp;
                        <span className="m-red" style={{ fontSize: "20px" }}>
                          *
                        </span>
                      </p>
                      <Rate
                        value={propertytype}
                        // color={'blue'} {setpropertytype
                        // }

                        onChange={(e) => {
                          setpropertytype(e);
                          changescheckingfunc("Property type updated");
                        }}
                      />
                    </Card>
                  )}

                  {(showserapart ||
                    (propertytypeoption !== "Hotel" &&
                      propertytypeoption !== "PG/Hostel" &&
                      propertytypeoption !== "Resort" &&
                      basic[0]?.propertytypeoption ===
                        "Service Apartment")) && (
                    <Card
                      className="mt-2 mt-sm-0 col-12 col-sm-8 float-start service_apt"
                      style={{
                        background: "#f4f4f4",
                        boxShadow: "rgb(207 202 202) 0px 0px 10px",
                      }}
                    >
                      {/* <p>Give your home a rating to help set expectations for travelers stay</p>  */}
                      <Select
                        onChange={handleChangeselect}
                        style={{ width: 200 }}
                        placeholder="Select Service Apartment"
                        defaultValue={serviceApp || basic[0]?.serviceApp}
                        options={proptertytypeload.map((element, i) => {
                          return {
                            value: element.PropertyType,
                            label: element.PropertyType,
                          };
                        })}
                        id="select_ser_app"
                      />
                      &nbsp;
                      <span className="m-red" style={{ fontSize: "20px" }}>
                        *
                      </span>
                    </Card>
                  )}
                  {/* </div> */}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-12 col-md-6 col-lg-5">
                  <h5>Time</h5>

                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-sm-9">
                        Check-In
                        <div className="col-12 col-sm-12 col-lg-12 px-lg-1 px-sm-1 col-4 px-1 ps-1 ps-sm-1 date_in d-flex">
                          <Select
                            disabled={checked}
                            value={
                              checkintime ||
                              (basic[0]?.checkintime === "24" && "12")
                            }
                            onChange={(value) => {
                              setcheckintime(value);
                              changescheckingfunc(
                                "Check-in & check-out time updated"
                              );
                            }}
                            defaultValue={
                              checkintime ||
                              (basic[0]?.checkintime === "24" && "12")
                            }
                            className="d-inline me-2"
                            placeholder="12"
                            options={[
                              {
                                value: "01",
                                label: "01",
                              },
                              {
                                value: "02",
                                label: "02",
                              },
                              {
                                value: "03",
                                label: "03",
                              },
                              {
                                value: "04",
                                label: "04",
                              },
                              {
                                value: "05",
                                label: "05",
                              },
                              {
                                value: "06",
                                label: "06",
                              },
                              {
                                value: "07",
                                label: "07",
                              },
                              {
                                value: "08",
                                label: "08",
                              },
                              {
                                value: "09",
                                label: "09",
                              },
                              {
                                value: "10",
                                label: "10",
                              },
                              {
                                value: "11",
                                label: "11",
                              },
                              {
                                value: "12",
                                label: "12",
                              },
                            ]}
                          />
                          <Select
                            className="d-inline me-2"
                            value={
                              checkintype ||
                              (basic[0]?.checkintime === "24" && "PM")
                            }
                            onChange={(value) => {
                              setcheckintype(value);
                              changescheckingfunc(
                                "Check-in & check-out time updated"
                              );
                            }}
                            defaultValue={
                              checkintype ||
                              (basic[0]?.checkintime === "24" && "PM")
                            }
                            disabled={checked}
                            placeholder="AM"
                            options={[
                              {
                                value: "AM",
                                label: "AM",
                              },
                              {
                                value: "PM",
                                label: "PM",
                              },
                            ]}
                          />
                        </div>
                        <label style={{ width: "100%" }}>Check-Out</label>
                        <div className="col-12 col-sm-12 col-lg-12 px-lg-1 px-sm-1 col-4 px-1 ps-1 ps-sm-1 date_in d-flex">
                          <Select
                            onChange={(value) => {
                              setcheckoutime(value);
                              changescheckingfunc(
                                "Check-in & check-out time updated"
                              );
                            }}
                            value={
                              checkouttime ||
                              (basic[0]?.checkintime === "24" && "12")
                            }
                            disabled={checked}
                            className="d-inline me-2"
                            defaultValue={
                              checkouttime ||
                              (basic[0]?.checkintime === "24" && "12")
                            }
                            placeholder="12"
                            options={[
                              {
                                value: "01",
                                label: "01",
                              },
                              {
                                value: "02",
                                label: "02",
                              },
                              {
                                value: "03",
                                label: "03",
                              },
                              {
                                value: "04",
                                label: "04",
                              },
                              {
                                value: "05",
                                label: "05",
                              },
                              {
                                value: "06",
                                label: "06",
                              },
                              {
                                value: "07",
                                label: "07",
                              },
                              {
                                value: "08",
                                label: "08",
                              },
                              {
                                value: "09",
                                label: "09",
                              },
                              {
                                value: "10",
                                label: "10",
                              },
                              {
                                value: "11",
                                label: "11",
                              },
                              {
                                value: "12",
                                label: "12",
                              },
                            ]}
                          />
                          <Select
                            className="d-inline me-2"
                            value={
                              checkouttype ||
                              (basic[0]?.checkintime === "24" && "PM")
                            }
                            disabled={checked}
                            onChange={(value) => {
                              setcheckouttype(value);
                              changescheckingfunc(
                                "Check-in & check-out time updated"
                              );
                            }}
                            placeholder="PM"
                            options={[
                              {
                                value: "AM",
                                label: "AM",
                              },
                              {
                                value: "PM",
                                label: "PM",
                              },
                            ]}
                          />
                        </div>
                      </div>
                      <div
                        className="col-sm-3 col-2 px-1 ms-3 ms-sm-0 mt-2 d-flex align-items-center ps-1 ps-sm-2"
                        style={{ borderLeft: "2px solid #c4c4c4" }}
                      >
                        <Checkbox
                          onChange={onChangechecktime}
                          defaultChecked={checked}
                          checked={checked}
                          id="cheoneday"
                        >
                          <b>24&nbsp;hrs</b>
                        </Checkbox>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 pr-1">
                  <h5>
                    Property Description&nbsp;<span className="m-red">*</span>
                  </h5>

                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <TextArea
                      rows={1}
                      ref={propdescref}
                      value={propdescription}
                      onChange={(event) => {
                        setpropdescription(
                          event.target.value.replaceAll("  ", " ")
                        );
                        changescheckingfunc("Property description updated");
                      }}
                      id="pro_descrip"
                      style={{ resize: "none" }}
                      onInput={propInput}
                      placeholder="Property Description"
                    />
                  </Card>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-sm-6 col-lg-5 pr-1">
                  <h5>
                    GSTIN&nbsp;<span className="m-red">*</span>
                  </h5>
                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <div className="row ">
                      <div className="col-9 col-sm-8 col-xs-6">
                        <Input
                          placeholder="GSTIN"
                          maxLength={15}
                          value={gstnumber}
                          onChange={(event) => {
                            setgstnumber(event.target.value.toUpperCase());
                            basic_Verifiedbut(false);
                            setlegalname("");
                            changescheckingfunc("GSTIN updated");
                            addToLocations([])
                          }}
                          id="gst_number"
                          onInput={propInput}
                          className="uppercasetext"
                        />
                        {contextHolder}
                      </div>
                      <div className="col-sm-4 col-2 px-0 d_v_c">
                        {basicverify === false ? (
                          <Button type="primary" onClick={gstnumverification}>
                            Verify GSTIN
                          </Button>
                        ) : (
                          <>
                            <span className="verified_but">
                              <CheckOutlined style={{ color: "#02cc02" }} />{" "}
                              <span
                                style={{ color: "#02cc02", fontWeight: "bold" }}
                              >
                                {" "}
                                &nbsp;Verified
                              </span>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-0">
                  <h5>Legal Name </h5>
                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                      height: "56px",
                    }}
                  >
                    <div className="row ">
                      <div className="col-12">
                        {(legalname || basic[0]?.legalname) && (
                          <Input
                            placeholder=""
                            maxLength={15}
                            value={legalname}
                            id="gst_legal"
                            readOnly
                          />
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-sm-12 col-lg-9 pr-1 ">
                  <h5>Cancellation Policy</h5>

                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <p>
                          How many days in advance can guests cancel free of
                          charge?
                        </p>
                        <Select
                          onChange={(value) => {
                            setcancelpolicy1(value);

                            if (value == "Day of arrival (6 pm)") {
                              setPolicies1("6 PM");
                            } else {
                              setPolicies1(value);
                            }
                            changescheckingfunc("Cancellation policy updated");
                          }}
                          value={Policies1 || "1 day"}
                          style={{ width: 200 }}
                          // placeholder='Select Channel Manager'
                          options={optionscancelpolicy1}
                        />
                      </div>
                      <div
                        className="col-sm-12 col-md-1 d-flex justify-content-center align-items-center"
                        style={{ fontSize: "18px" }}
                      >
                        <b>Or</b>
                      </div>
                      <div className="col-sm-12 col-md-5 col-lg-4">
                        <p>guests will pay 100%</p>
                        <Select
                          onChange={(value) => {
                            setcancelpolicy2(value);
                            setPolicies2(value);
                            changescheckingfunc("Cancellation policy updated");
                          }}
                          style={{ width: 200 }}
                          value={Policies2 ||basic[0]?.Policies2 || "the room night"}
                          options={optionscancelpolicy2}
                        />
                      </div>
                    </div>

                    {/* <p>Choose one from the options below:</p> */}

                    <div className="row mt-2">
                      <div className="col-sm-12">
                        <div className="alert alert-primary mt-sm-3">
                          <span className="setcancelation">
                            The guest must cancel by 
                            {/* &nbsp;<RoughNotation type="circle" show={animationCountshow}>{" "} */}
                            {Policies1 || " 1 day"}
{/* </RoughNotation> &nbsp; */}
 on
                            the day of arrival or pay 100% of{" "}
                           
                            {/* &nbsp; <RoughNotation type="box" show={animationCountshow}>{" "} */}
                             {Policies2 || " the room night."} 
                             {/* </RoughNotation> &nbsp; */}
                             
                          </span>
                          <br />
                          <span style={{ fontSize: "12px" }}>
                            You'll be able to make changes to your policies
                            later on – this is just to get you started.
                          </span>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-9 col-lg-6">
                  <h5>Do you work with Channel Manager</h5>

                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                      display: "flex",
                    }}
                    className="channel-manager"
                  >
                    <Radio.Group
                      id="c_manager_group"
                      defaultValue={
                        channelmanageroption || basic[0]?.channelmanageroption
                      }
                      value={
                        channelmanageroption || basic[0]?.channelmanageroption
                      }
                      onChange={onChangeradiochnlmanager}
                    >
                      <Radio value={"Yes"}>
                        <b>Yes</b>
                      </Radio>

                      <Radio value={"No"}>
                        <b>No</b>
                      </Radio>
                    </Radio.Group>

                    {/* { ((showchnlmanger || showchnlmanger !== false) || channelmanageroption ==="Yes" || showchnlmanger === true && basic[0]?.channelmanageroption === "Yes") || basic[0]?.channelmanageroption === "Yes"
                     */}
                    {(showchnlmanger ||
                      channelmanageroption === "Yes" ||
                      (channelmanageroption !== "No" &&
                        basic[0]?.channelmanageroption === "Yes")) && (
                      <div className="w-75 px-0 channel_manager_select">
                        <AutoComplete
                          className="col-sm-6 text-50 channel_manager_radio"
                          // value={selectchlmger}
                          defaultValue={basic[0]?.Channelmanager}
                         
                          filterOption={(inputValue, option) =>
                            option.value
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                          onChange={(value) => {
                            setselectchlmger(value);
                            setotherstext("");
                            const data = {
                              ChannelManager: value,
                            };
                            const responsepropertytype = axios
                              .post(
                                `${onboardapi}/Selectchannelmanagerid`,
                                data
                              )
                              .then((res) => {
                                setchnlmanagerïd(res.data[0].Id);
                              })
                              .catch((err) => {
                               
                              });

                            if (value === "Others") {
                              setTextBox(true);
                            } else {
                              setTextBox(false);
                            }
                            setChannelmanager(value);
                            changescheckingfunc("Channel manager updated");
                          }}
                          key={numincrement + 1}
                          style={{ width: 200 }}
                          placeholder="Select Channel Manager"
                          options={ch_ch}
                        />
                        {(textBox ||
                          (basic[0]?.Channelmanager === "Others" &&
                            (Channelmanager === "Others" ||
                              Channelmanager === undefined))) && (
                          <Input
                            type="text"
                            value={otherstext}
                            onChange={(event) => {
                              setotherstext(
                                event.target.value.replaceAll("  ", " ")
                              );
                              changescheckingfunc("Channel manager updated");
                            }}
                            placeholder="Enter the Channel Manager"
                            className="mt-1 ms-0 ms-md-1 col-sm-6 text-50 mx-md-1 channel_manager_text"
                            onInput={propInput}
                          />
                        )}
                      </div>
                    )}
                  </Card>
                </div>
                <div className="col-sm-3 col-lg-3">
                  <h5>Website Address</h5>
                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <Input
                      placeholder="Website Address"
                      onChange={(event) => {
                        setwebsiteAddress(
                          event.target.value.replaceAll(" ", "")
                        );
                        changescheckingfunc("Website address updated");
                      }}
                      value={websiteAddress}
                      id="web_site"
                      onInput={propInput}
                    ></Input>
                  </Card>
                </div>
              </div>

              <div className="row mt-3 mb-4">
                <div className="col-sm-12 col-lg-9">
                  <div className="float-end">
                    <Button
                      type="primary"
                      style={{ minWidth: "100px" }}
                      onClick={showModal}
                    >
                      Save & Continue
                      {/* <NavLink to="/location"></NavLink> */}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <Modal
        className="error_msg"
        title="Error message"
        open={open}
        onCancel={handleCancel}
        footer={[
          <Button key="back" type="primary" onClick={handleCancel}>
            Close
          </Button>,
        ]}
        maskClosable={false}
      >
        {errormessagearr &&
          errormessagearr.map((item, index) => <p key={index}>{item}</p>)}
      </Modal>
    </>
  );
}

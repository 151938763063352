import { useState } from "react";
import { Card, Steps, Button, Spin, Modal, Upload } from "antd";
import "../styles/propinfo.css";
import "antd/dist/antd.css";
import pic from "../assets/unnamed.png";
import photoheadpic from "../assets/ec-photos@2x.png";

import { NavLink } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

import axios from "axios";

import { useNavigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default function Photos() {
  const {
    basic,
    property_update,
    propImages,
    deletedpropimages,
    deleteimages,
    addToPropImages,
    propImagespath,
    addToPropImagespath,
    changescheckingfunc,
    screenimages,
    photasscreenimages,
    logo_img,
    roomDetails
  } = useContext(PropertyContext);

  const navigate = useNavigate();
  const [propphoto, setpropphoto] = useState([]);
  const [errorphoto, seterrorphoto] = useState("");
  const [errorphotofilesize, seterrorphotofilesize] = useState("");
  const [updateimage, setUpdateimage] = useState([]);
  const [updbimage, setdbimage] = useState([]);
  const [dataChange, setDataChange] = useState("");

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [Openerorimg, setOpenerrorimg] = useState(false);
  const [messagearrimg, setmessagearrimg] = useState([]);

  const [fileList, setFileList] = useState([
    // {
    //   uid: '1',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://endpoint887127.azureedge.net/clogo/Tata_Capital.jpg',
    // },
    // {
    //   uid: '-1',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-2',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-3',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-4',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-xxx',
    //   percent: 50,
    //   name: 'image.png',
    //   status: 'uploading',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-5',
    //   name: 'image.png',
    //   status: 'error',
    // },
  ]);
 
  const [open, setOpen] = useState(false);
  const [file, setfile] = useState("");
  const [file1, setfile1] = useState("");
  const [file2, setfile2] = useState("");
  const [filename, setfilename] = useState();
  // const [Imagepath, setImagepath] = useState([]);
  const [imagegreaterthan, setimagegreaterthan] = useState(false);
  const [openerror, setopenerror] = useState(false);
  const handleCancel = () => setPreviewOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileCount, setFileCount] = useState(false);
  const [deleteFile, setdeleteFile] = useState(deleteimages);
  const [newimage, setnewimage] = useState([]);

 


  // delete and insert images
  

  let filesizeerror = false;
  let newfile = [];
  // const [propphoto,setpropphoto]=useState("")
  let Imagepath1 = [];
  const handleCancelbtn = () => {
    setOpen(false);
  };

  const handleCancelbtnerror = () => {
    setopenerror(false);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload{" "}
        <small>
          <br />
          (.jpg, .jpeg, .png) <br />
          (Maximum upload size limit 2mb)
        </small>{" "}
        <span className="m-red"> *</span>
      </div>
    </div>
  );

  useEffect(() => {
    if (basic[0]?.propertyname === "" || basic[0]?.propertyname === undefined) {
      navigate("/");
    }
  }, []);

  const fileinsert = () => {
    for (let i = 0; i <= fileList?.length; i++) {
      if (fileList[i]?.size > 1902250) {
        filesizeerror = true;
        setimagegreaterthan(true);
      }
    }

    let filedata = document.querySelector(".ant-upload");

    let remove_error = document.querySelectorAll(".border_red");

    remove_error.forEach((item) => item.classList.remove("border_red"));

    if (fileList.length === 0 || fileList.length > 3) {
      setopenerror(true);
      seterrorphotofilesize("");
      seterrorphoto(
        "* Minimum 1 property image needed & maximum 3 images allowed"
      );
      let fileuploadeddata = document.querySelector(
        ".ant-upload-list-picture-card-container"
      );
      if (!fileuploadeddata) {
        filedata?.classList.add("border_red");
      }
    } else if (imagegreaterthan === true || filesizeerror === true) {
      setopenerror(true);
      seterrorphoto("");
      seterrorphotofilesize("");
      seterrorphotofilesize("* Image should be less than 2 mb");
    } else {
      var form = document.getElementById("formimg");
      const formData = new FormData(form);
      // const formData2 = new FormData(form);
      // const formData3 = new FormData(form);

      //filter new file

      newfile = [...new Set(fileList.filter((item) => isNaN(item.uid)))];

      for (let i = 0; i < newfile.length; i++) {
        formData.append(
          "files",
          newfile[i]?.originFileObj || propImages[i]?.originFileObj
        );
      }

      if (newfile.length > 0 && fileCount === true && filesizeerror === false) {
        setIsLoading(true);

        let response = axios
          .post(`${property_update}/imageuploadmultiple`, formData)
          .then((res) => {
            // Imagepath1.push({path:res.data.path[j],name:updateimage[j]?.name})
            // addToPropImagespath(fileList.map((item,index)=> !isNaN(item.uid))
            // .map((item,i)=> ( {uid:0,imagename:item.name,ImageLocation:res.data.path[i],imagename1:item.name,ImageLocation1:res.data.path[i]}) ))

            let oldfilelistarray = fileList.filter(
              (item, index) => !isNaN(item.uid)
            );
            let newfilelistarray = fileList.filter((item, index) =>
              isNaN(item.uid)
            );
            let addnewfilename = newfilelistarray.map((item, i) => ({
              uid: 0,
              imagename: item.name,
              ImageLocation: res.data.path[i],
              imagename1: item.name,
              ImageLocation1: res.data.path[i],
            }));
            //setImagepath((pre) => [...pre,res.data]);

            addToPropImagespath([...addnewfilename]);
          
           
        
            let filelistimage = fileList
            ?.filter((item) => isNaN(item.uid))
            .map((item) => ({...item })).flat(Infinity);
            
            
             // delete and new image upload 
             let newimageslist = filelistimage.map((item, i) => ({
              uid: 0,
              imagename: item.name,
              ImageLocation: res.data.path[i],
              imagename1: item.name,
              ImageLocation1: res.data.path[i],
            }))

            setnewimage(newimageslist)
             
    
          
            
           

            if (res.data.message === "Success") {
              setUpdateimage([]);
            navigate("/Profile");
              setIsLoading(false);
            }

            changescheckingfunc("Property images updated");
            setFileCount(false);
            newfile = [];
          })
          .catch((error) => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      //  navigate("/Profile");
        // if(updateimage.length === 0) {
        navigate("/Profile");
        // }
      }
     navigate("/Profile");
    }
    //setIsLoading(true);
    // addToPropImagespath(Imagepath1);
    addToPropImages(fileList, Imagepath1);
    

           let combineoldnewimage = deleteFile;
           
           deletedpropimages(combineoldnewimage);
           
    
    

    
  };

  const handleChangephoto = ({ fileList: newFileList }) => {
    
    let fileextcheck = newFileList.filter(image => (image?.name.toLowerCase().endsWith('.jpg') || image?.name.toLowerCase().endsWith('.jpeg') || image?.name.toLowerCase().endsWith('.png')) && image );
    let fileexterrorcheck = newFileList.filter(image => (!image?.name.toLowerCase().endsWith('.jpg') && !image?.name.toLowerCase().endsWith('.jpeg') && !image.name.toLowerCase().endsWith('.png') ) && image );

   if(fileextcheck?.length >= 0) {

    setFileCount(true);
    setFileList(fileextcheck);
    setpropphoto(fileextcheck);

    setfile(fileextcheck[0]?.originFileObj);
    setfile1(fileextcheck[1]?.originFileObj);
    setfile2(fileextcheck[2]?.originFileObj);
   }
   if(fileexterrorcheck?.length > 0) {
   
    setOpenerrorimg(true);
    
   

    setmessagearrimg([ "* Supported file formats are (.jpg, .jpeg, .png)"]);
   
   }
   

   

    // newFileList.map(item => isNaN(item.uid) ? setUpdateimage(pre => [...new Set([...pre,item])]) : setdbimage(pre => [...pre,item]))

    

    // setfilename(newFileList[0].name)
    let filedata = document.querySelector(".ant-upload");
    let fileuploadeddata = document.querySelector(
      ".ant-upload-list-picture-card-container"
    );
    if (!fileuploadeddata) {
      filedata?.classList.remove("border_red");
    }
    setDataChange("You have unsaved data, do you want to continue?");
  };
  const handleCancelmodal = () => {
    setOpenerrorimg(false);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    if (propImages) {
      setFileList(propImages);
    }
  }, []);
  const removefile = (e) => {
    let removefile = fileList.filter((item) => item.uid !== e.uid);
    let removedfile = fileList.filter((item) => item.uid === e.uid);

    setFileList(removefile);
    // addToPropImages(removefile)
    photasscreenimages(removefile);
    changescheckingfunc("Property images updated");
    setimagegreaterthan(false);
    setdeleteFile((pre) => [...pre, removedfile]);
  
  };
  
 
  return (
    <div>
      {isLoading && (
        <div className="loaderloading">
          <div className="example">
            <Spin />{" "}
            <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
              Loading ...
            </span>
          </div>
        </div>
      )}
      <nav className="row">
        <a className="navbar-brand col-md-2 text-center mobile-show" href="#">
          <img className="" src={logo_img} alt="" width={"120px"} height={"85px"} />
        </a>
        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon">fsfds</span>
      </button> */}
        <span className="text-center col-md-12 d-flex justify-content-center align-items-center">
          <h3>
            <b>Property Onboarding</b>
          </h3>
        </span>
      </nav>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-md-2 no-border-l h100"
            style={{ background: "#fff", borderLeft: "none" }}
          >
            <Card className=" col-md-12 h-100 side-menu">
              <Steps
                size="small"
                className="mt-md-5 cursor-not-allowed mobile-hide"
                direction="vertical"
                current={4}
              >
                <Steps.Step title={<NavLink to="/">Basics</NavLink>} />
                <Steps.Step
                  title={<NavLink to="/location">Location</NavLink>}
                />
                <Steps.Step
                  title={<NavLink to="/facilities">Facilities</NavLink>}
                />
                <Steps.Step
                  title={<NavLink to="/roomdet">Room details</NavLink>}
                />
                <Steps.Step title={<NavLink to="/photos">Photos</NavLink>} />
                <Steps.Step title={<NavLink to="/Profile">Contact</NavLink>} />
                <Steps.Step title={<NavLink to="/Publish">Publish</NavLink>} />
              </Steps>
              <a
                className="navbar-brand col-md-2 text-center logo-display"
                href="#"
              >
                <img
                  className="logo_name"
                  src={logo_img}
                  alt=""
                  width="100%"
                />
              </a>
              <p className="mt-sm-3 desk_browser" style={{fontSize: "12px"}}><span className="fw-bold" style={{fontSize: "14px",marginBottom:"10px"}}>Supported Browsers : </span><br/> <span style={{margin:"0px",lineHeight:"10px"}}></span>1. Google Chrome,<br/>2. Microsoft Edge,<br/>3. Firefox.</p>
            </Card>
          </div>
          <div
            className="col-md-10 layout-ml"
            style={{ background: "rgb(227 245 255)" }}
          >
            <div className="row ">
              <div className="col-sm-6 p-3">
                <h3>Show them what they’re missing.</h3>
                <p>
                  Pictures matter to travelers. Upload as maximum three
                  high-quality images as you have.
                </p>
              </div>
              <div className="col-sm-6 mt-2">
                <form id="formimg" action="#" encType="multipart/form-data">
                  <img src={photoheadpic} alt="" height={"120px"} />
                </form>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-9 photos">
                <h5>
                  Property Images - <small>maximum 3 images</small>
                </h5>
                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  {/* <h3>Property Photos</h3> */}

                  <div className="row">
                    <Upload
                      // action="https://www.mocky.io/v2/5cc8019d300000980a0f55e76"
                      listType="picture-card"
                      fileList={fileList}
                      accept={".jpg, .jpeg, .png"}
                      // action={"http://localhost:8000/"}
                      name="multifiles"
                      onPreview={handlePreview}
                      onChange={handleChangephoto}
                      multiple={true}
                      onRemove={removefile}
                      beforeUpload={(file) => {
                        const isLt2M = file.size / 1024 / 1024 < 2;
                        if (!isLt2M) {
                          // message.error("Image must smaller than 2MB!");
                          setimagegreaterthan(true);
                          return true;
                        } else {
                          setimagegreaterthan(false);
                          return false;
                        }
                      }}
                      className="prop_upload_error prop_upload_scrollbar"
                    >
                      {fileList.length >= 3 ? null : uploadButton}
                    </Upload>
                    <Modal
                      open={previewOpen}
                      title={previewTitle}
                      className="error_msg"
                      onCancel={handleCancel}
                      footer={[
                        <Button key="back" type="primary" onClick={handleCancel}>
                          Close
                        </Button>,
                      ]}
                    >
                      <img
                        alt="example"
                        style={{
                          width: "100%",
                        }}
                        src={previewImage}
                        maskClosable={false}
                      />
                    </Modal>
                  </div>
                </Card>
                <div className="float-end mt-3 mt-sm-3 mb-4">
                  <Button
                    type="primary"
                    style={{ minWidth: "100px", marginRight: "5px" }}
                  >
                    <NavLink to="/roomdet">Previous</NavLink>
                  </Button>

                  <Button
                    type="primary"
                    className="ms-sm-3"
                    style={{ minWidth: "100px" }}
                    onClick={fileinsert}
                  >
                    Save & Continue
                    {/* <NavLink to="/Profile"> </NavLink> */}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Title"
        open={open}
        onCancel={handleCancelbtn}
        maskClosable={false}
      ></Modal>
      <Modal
              className="error_msg"
              title="Error message"
              open={Openerorimg}
              onCancel={handleCancelmodal}
              footer={[
                <Button
                  key="back"
                  type="primary"
                  className="dblclick"
                  onClick={handleCancelmodal}
                >
                  Close
                </Button>,
              ]}
              maskClosable={false}
            >
              {messagearrimg &&
                messagearrimg.map((item, index) => <p key={index}>{item}</p>)}
            </Modal>
      <Modal
        className="error_msg"
        title="Error message"
        open={openerror}
        onCancel={handleCancelbtnerror}
        footer={[
          <Button key="back" type="primary" onClick={handleCancelbtnerror}>
            Close
          </Button>,
        ]}
        maskClosable={false}
      >
        <p>{errorphoto}</p>
        <p>{errorphotofilesize}</p>
      </Modal>
    </div>
  );
}

// import { Place } from "@mui/icons-material";

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Facilities from "../Components/Facilities";
import Location from "../Components/Location";
import Photos from "../Components/Photos";
import Place from "../Components/Place";
import Places from "../Components/Places";
import Profile from "../Components/Profile";
import { Link } from "react-router-dom";
import Propcreation from "../Components/Propcreation";
import Propertydet from "../Components/Propertydet";
import Publish from "../Components/Publish";
import Roomdet from "../Components/Roomdet";
import Search from "../Components/Search";

import { useState } from "react";


// import Property from "./Property";



export default function MainRout() {
  const [data1,setData1]=useState([])
  const [propname,setpropname] =useState("")
  const [gstnum,setgstnum]=useState("")
//console.log(data1)
setTimeout(() => {
  //console.log("propertyname",propname,gstnum && gstnum) 
  //console.log("Inside the Main Rout")
},300);

   
  return (
    <BrowserRouter>
     <Routes>
   
      <Route path={"/select"} element={<Propertydet  />}>
      </Route>
      <Route path={"/location"} element={<Location />}>
        </Route>
      <Route path={"/"}  element={<Propcreation setpropname={setpropname} setgstnum={setgstnum} />}>
      <Route path={"/:id"}  element={<Propcreation setpropname={setpropname} setgstnum={setgstnum} />}></Route>
        </Route>
        <Route path={"/roomdet"} element={<Roomdet />}>
        </Route>
        <Route path={"/photos"} element={<Photos />}>
        </Route>
        <Route path={"/facilities"} element={<Facilities/>}>
        </Route>
        {/* <Route path={"/Places"} element={<Places />}>
        </Route> */}
        <Route path={"/Profile"} element={<Profile setData1={setData1} />}>
        </Route>
        <Route path={"/Publish"} element={<Publish data1={data1} propname={propname} gstnum={gstnum}/>}>
        </Route>
        <Route path={"/Search"} element={<Search />}>
        </Route>
       
       
      </Routes>
  </BrowserRouter>
  )
}

import { useState, useEffect, useContext } from "react";
import roomheadpic from "../assets/ec-publish@2x.png";
import {
  Card,
  Steps,
  Input,
  Spin,
  Button,
  AutoComplete,
  Modal,
  Upload,
  Radio,
  Popconfirm,
  Select,
  DatePicker,
  Space,
  Table,
  Tag,
} from "antd";
import "../styles/propinfo.css";
import "antd/dist/antd.css";
import pic from "../assets/unnamed.png";
import { NavLink, useNavigate } from "react-router-dom";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default function Roomdet() {
  const {
    basic,
    property_update,
    propInput1,
    roomDetails,
    addToRoomDetails,
    textNum,
    percentagevalidate,
    changescheckingfunc,
    changeschecking,
    logo_img,
  } = useContext(PropertyContext);
  const [RoomType, setRoomType] = useState("");
  const [ValidityFrom, setValidityFrom] = useState("");
  const [ValidityTo, setValidityTo] = useState("");
  const [SingleRackRate, setSingleRackRate] = useState("");
  const [SingleDiscountRate, setSingleDiscountRate] = useState("");
  const [DoubleDiscountRate, setDoubleDiscountRate] = useState("");
  const [DoubleRackRate, setDoubleRackRate] = useState("");
  const [Tacper, setTacper] = useState("");

  const [Startvalue, setStartValue] = useState("");
  const [checkoutdt, setcheckoutdt] = useState("");
  const [endValue, setEndValue] = useState("");
  const [checkindt, setcheckindt] = useState("");
  const [Inclusions, setInclusions] = useState(["WIFI"]);
  const [showbtcperiod, setshowbtcperiod] = useState(false);

  const [Openeror, setOpenerror] = useState(false);
  const [btccreditperiod, setbtccreditperiod] = useState(
    roomDetails?.btccreditperiod
  );
  const [btccreditperiodoption, setbtccreditperiodoption] = useState(
    roomDetails?.btccreditperiodoption || "No"
  );
  const [trafficoption, settrafficoption] = useState(
    roomDetails?.trafficoption || ""
  );
  const [errormessagearr, setmessagearr] = useState([]);
  const [errorphotofilesize, seterrorphotofilesize] = useState(false);
  const { CheckableTag } = Tag;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [dataChange, setDataChange] = useState("");
  const [file, setfile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [disabletrue, setdisabletrue] = useState(false);
  const [cart, setCart] = useState([
    { roomtype: null, singletariff: null, doubletariff: null, mealplan: null },
  ]);
  const [mealplan, setmealplan] = useState("CP - Breakfast");
  const [percentage, setPercentage] = useState(true);
  const [openprevious, setOpenprevious] = useState("");
  const [clicked, setClicked] = useState(false);
  const [DataSource, SetDataSource] = useState([]);
  const [newDataSource, SetnewDataSource] = useState([]);
  const [Openerorimg, setOpenerrorimg] = useState(false);
  const [messagearrimg, setmessagearrimg] = useState([]);
  const navigate = useNavigate();
  let per_value = true;

  let addlist = document.querySelector(".addlist");
  let antpickertodaybtn = document.querySelector(".ant-picker-today-btn");
  const handleClick = () => {
    setClicked(true);
    setIsLoading(true);

    let btc = document.querySelector(".btc");
    let room_type = document.querySelector("#message");
    let date_in = document.querySelector("#date_in");
    let date_out = document.querySelector("#date_out");
    let singlerackrate = document.querySelector(".singlerackrate");
    let singlediscount = document.querySelector(".singlediscount");
    let doublerate = document.querySelector(".doublerate");
    let doublediscountrate = document.querySelector(".doublediscountrate");
    let meal_plan = document.querySelector(".mealplan >  div > span > input");
    let mealplan_input = document.querySelector(
      ".mealplan >  div > span.ant-select-selection-item"
    );
    let tac = document.querySelector(".tac");
    let prop_upload = document.querySelector(".prop_upload");

    let remove_error = document.querySelectorAll(".border_red");

    remove_error.forEach((item) => item.classList.remove("border_red"));

    setValidityTo(moment(endValue._d).format("DD-MM-YYYY").toString());

    setmessagearr([]);

    if (room_type?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the  room type"]);
      room_type?.closest(".ant-select-selector").classList.add("border_red");
      //RoomType.classList.add("border_red");
    }
    if (date_in?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the validity from date"]);
      date_in?.closest(".ant-picker").classList.add("border_red");
    }
    if (singlerackrate?.value === "") {
      setmessagearr((pre) => [
        ...pre,
        "* Please enter the single rack rate and rate should be 0 or greater than 350",
      ]);
      singlerackrate?.classList.add("border_red");
    } else {
      if (parseInt(singlerackrate?.value) !== 0) {
        if (parseInt(singlerackrate?.value) < 350) {
          setmessagearr((pre) => [
            ...pre,
            "* Single rack rate should be greater than or equal to 350",
          ]);
          singlerackrate?.classList.add("border_red");
        }
      }
    }
    if (singlediscount?.value === "") {
      setmessagearr((pre) => [
        ...pre,
        "* Please enter the single discount rate and rate should be 0 or greater than 350",
      ]);
      singlediscount?.classList.add("border_red");
    } else {
      if (parseInt(singlediscount?.value) !== 0) {
        if (parseInt(singlediscount?.value) < 350) {
          setmessagearr((pre) => [
            ...pre,
            "* Single discount rate should be greater than or equal to 350 & less than or equal to single rack rate",
          ]);
          singlediscount?.classList.add("border_red");
        }
      }
    }

    // if(parseInt(singlediscount?.value) > parseInt(singlerackrate?.value))
    // {setmessagearr((pre) => [...pre, "* Single discount rate should be less than or equal to 350"]);}

    if (parseInt(SingleDiscountRate) > parseInt(SingleRackRate)) {
      setmessagearr((pre) => [
        ...pre,
        "* Single discount rate should be greater than or equal to 350 & less than or equal to single rack rate",
      ]);
      singlediscount?.classList.add("border_red");
    }

    if (doublerate?.value === "") {
      setmessagearr((pre) => [
        ...pre,
        "* Please enter the double rack rate and rate should be 0 or greater than 350",
      ]);
      doublerate?.classList.add("border_red");
    } else {
      if (parseInt(doublerate?.value) !== 0) {
        if (parseInt(doublerate?.value) < 350) {
          setmessagearr((pre) => [
            ...pre,
            "* Double rack rate should be greater than or equal to 350",
          ]);
          doublerate?.classList.add("border_red");
        }
      }
    }
    if (doublediscountrate?.value === "") {
      setmessagearr((pre) => [
        ...pre,
        "* Please enter the double discount rate and rate should be 0 or greater than 350",
      ]);
      doublediscountrate?.classList.add("border_red");
    } else {
      if (parseInt(doublediscountrate?.value) !== 0) {
        if (parseInt(doublediscountrate?.value) < 350) {
          setmessagearr((pre) => [
            ...pre,
            "* Double discount rate should be greater than or equal to 350 & less than or equal to double rack rate",
          ]);
          doublediscountrate?.classList.add("border_red");
        }
      }
    }
    // if(parseInt(doublediscountrate?.value) > parseInt(doublerate?.value))

    //   {setmessagearr((pre) => [...pre, "* Double discount rate should be less than or equal to 350"]);}

    if (parseInt(SingleRackRate) === 0 && parseInt(DoubleRackRate) === 0) {
      setmessagearr((pre) => [
        ...pre,
        "* The single rack rate or double rack rate should be greater than or equal to 350",
      ]);
    }

    if (parseInt(DoubleDiscountRate) > parseInt(DoubleRackRate)) {
      setmessagearr((pre) => [
        ...pre,
        "* Double discount rate should be greater than or equal to 350 &  less than or equal to double rack rate",
      ]);
      doublediscountrate?.classList.add("border_red");
    }

    // if (mealplan === "") {
    //   setmessagearr((pre) => [...pre, "* Please enter the meal plan"]);
    //   meal_plan?.closest(".ant-select-selector").classList.add("border_red");
    // }
    if (trafficoption === "") {
      setmessagearr((pre) => [
        ...pre,
        "* Please select the tariff include taxes",
      ]);
      meal_plan?.closest(".ant-select-selector").classList.add("border_red");
    }

    if (fileList.length === 0) {
      setmessagearr((pre) => [...pre, "* Please upload room image"]);
      prop_upload?.classList.add("border_red");
    } else if (errorphotofilesize === true) {
      setmessagearr((pre) => [...pre, "* Room image should be less than 2 mb"]);
      prop_upload?.classList.add("border_red");
    } else {
    }

    if (tac?.value !== "") {
      if (tac?.value > 50) {
        setmessagearr((pre) => [...pre, "* Maximum TAC percentage allowed 50"]);
      } else {
        if (
          /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/g.test(
            tac?.value
          )
        ) {
          setPercentage(true);

          per_value = true;
        } else {
          setPercentage(false);
          setmessagearr((pre) => [
            ...pre,
            "* Please enter valid TAC percentage",
          ]);

          per_value = false;
        }
      }
    }
    if (
      parseInt(singlerackrate?.value) >= 350 &&
      parseInt(singlediscount?.value) === 0
    ) {
      setmessagearr((pre) => [
        ...pre,
        "* Single discount rate should be greater than or equal to 350 & less than or equal to single rack rate",
      ]);
    }
    if (
      parseInt(doublerate?.value) >= 350 &&
      parseInt(doublediscountrate?.value) === 0
    ) {
      setmessagearr((pre) => [
        ...pre,
        "* Double discount rate should be greater than or equal to 350 & less than or equal to double rack rate",
      ]);
    }

    if (Inclusions.length === 0) {
      setmessagearr((pre) => [...pre, "* Please select minimum 1 Inclusion"]);
    }

    if (
      room_type?.value === "" ||
      date_in?.value === "" ||
      singlerackrate?.value === "" ||
      singlediscount?.value === "" ||
      (parseInt(singlerackrate?.value) !== 0 &&
        parseInt(singlerackrate?.value) < 350) ||
      (parseInt(singlediscount?.value) !== 0 &&
        parseInt(singlediscount?.value) < 350) ||
      (parseInt(singlerackrate?.value) >= 350 &&
        parseInt(singlediscount?.value) < 350) ||
      parseInt(singlediscount?.value) > parseInt(singlerackrate?.value) ||
      doublerate?.value === "" ||
      doublediscountrate?.value === "" ||
      (parseInt(doublerate?.value) !== 0 &&
        parseInt(doublerate?.value) < 350) ||
      (parseInt(doublediscountrate?.value) !== 0 &&
        parseInt(doublediscountrate?.value) < 350) ||
      (parseInt(doublerate?.value) >= 350 &&
        parseInt(doublediscountrate?.value) < 350) ||
      parseInt(doublediscountrate?.value) > parseInt(doublerate?.value) ||
      (parseInt(singlerackrate?.value) === 0 &&
        parseInt(doublerate?.value) === 0) ||
      trafficoption === "" ||
      per_value === false ||
      (tac?.value !== 0 && tac?.value > 50) ||
      fileList.length === 0 ||
      errorphotofilesize === true ||
      Inclusions.length === 0
    ) {
      setIsLoading(false);
      setOpenerror(true);
    } else {
      changescheckingfunc("Room details updated");
      setdisabletrue(true);
      let newcontactDetail;
      let incrementkey = 1;
      // let roomimages=[];
      var form = document.getElementById("formimg");
      const formData = new FormData(form);
      formData.append("file", file);

      let response = axios
        .post(`${property_update}/imageupload`, formData)
        .then((res) => {
          let addmealplan = mealplan.split("-");
          var str = res.data.path;
          var n = str.lastIndexOf("/");
          var result = str.substring(n + 1);

          newcontactDetail = {
            key: Math.floor(Math.random() * 100),
            id: 0,
            RoomType: RoomType,
            ValidityFrom:
              document.querySelector("#date_in")?.value || ValidityFrom,
            //ValidityTo: ValidityTo,
            ValidityTo: document.querySelector("#date_out")?.value,
            SingleRackRate: parseInt(singlerackrate?.value).toFixed(2),
            SingleDiscountRate: parseInt(singlediscount?.value).toFixed(2),
            DoubleRackRate: parseInt(doublerate?.value).toFixed(2),
            DoubleDiscountRate: parseInt(doublediscountrate?.value).toFixed(2),
            MealPlan: mealplan,
            Tacper: isNaN(parseFloat(Tacper).toFixed(2))
              ? "0.00"
              : parseFloat(Tacper).toFixed(2),
            Inclusions: [...Inclusions,addmealplan[1].trim()],
            RoomImagepreview: fileList[0]?.thumbUrl,
            //RoomImagename:fileList[0]?.name,
            RoomImagename: result,
            Roomfile: fileList[0].originFileObj,
            Roomimagepath: res.data.path,
            trafficoption: trafficoption,
            TACinclusive: 0,
            Temp: 0,
            Conference: 0,
            Visible: 1,
          };

          incrementkey++;
          // newcontactDetail.key = newcontactDetail.key + 1 || 1;
          //  if(newcontactDetail.)
          SetDataSource((pre) => {
            return [...pre, newcontactDetail];
          });

          SetnewDataSource((pre) => {
            return [...pre, newDataSource];
          });

          setIsLoading(false);
          setRoomType("");

          setSingleRackRate("");
          setSingleDiscountRate("");
          setDoubleRackRate("");
          setDoubleDiscountRate("");
          setmealplan("CP - breakfast");
          setTacper("");
          setInclusions(["WIFI"]);
          setFileList([]);
          setSelectedTags(["WIFI"]);
          settrafficoption("");
          if (antpickertodaybtn) {
            antpickertodaybtn.click();
            setStartValue("");
            setValidityTo("");
            setEndValue("");
            setdisabletrue(false);
          }
        })
        .catch((error) => {
          
          setdisabletrue(false);
        });
    }

    // setcheckindt(date._d);
    //setValidityFrom(dateString);
  };

  const options = [
    {
      value: "Standard",
    },
    {
      value: "Deluxe",
    },
    {
      value: "Executive",
    },
    {
      value: "Suite",
    },
    {
      value: "Premium",
    },
  ];

  const Nextpagerouting = () => {
    let btc = document.querySelector(".btc");
    let room_type = document.querySelector("#message");
    let date_in = document.querySelector("#date_in");
    let date_out = document.querySelector("#date_out");
    let singlerackrate = document.querySelector(".singlerackrate");
    let singlediscount = document.querySelector(".singlediscount");
    let doublerate = document.querySelector(".doublerate");
    let doublediscountrate = document.querySelector(".doublediscountrate");
    let meal_plan = document.querySelector(".mealplan >  div > span > input");
    let mealplan_input = document.querySelector(
      ".mealplan >  div > span.ant-select-selection-item"
    );
    let tac = document.querySelector(".tac");
    let prop_upload = document.querySelector(".prop_upload");

    setmessagearr([]);
    let room_type_room_details = document.querySelectorAll(
      ".ant-table-row.ant-table-row-level-0"
    );

    // let btc_val = document.querySelector(".btc");
  
    // if (btc_val?.value === "") {
    //   setmessagearr((pre) => [...pre, "* Please enter BTC / credit period"]);
    // }

    // if (parseInt(btc_val?.value) === 0) {
    //   setmessagearr((pre) => [
    //     ...pre,
    //     "* BTC / credit period value should be greater than 0",
    //   ]);
    // }
    if (
      room_type_room_details?.length === 0 ||
      room_type_room_details?.length === undefined
    ) {
      setmessagearr((pre) => [...pre, "* Please add room details to the list"]);
    }

    if (
      room_type?.value !== "" ||
      date_in?.value !== "" ||
      singlerackrate?.value !== "" ||
      singlediscount?.value !== "" ||
      doublerate?.value !== "" ||
      doublediscountrate?.value !== "" ||
      trafficoption !== "" ||
      fileList.length !== 0
    ) {
      setmessagearr([]);
      setmessagearr(["* Please add room details to the list"]);
      setOpenerror(true);
    } else if (
      room_type_room_details?.length === 0 ||
      room_type_room_details?.length === undefined 
      // btc_val?.value === "" ||
      // parseInt(btc_val?.value) === 0
    ) {
      setOpenerror(true);
    } else {
      let roomdetarr = DataSource;
      setIsLoading(true);
      addToRoomDetails({
        btccreditperiod:btccreditperiod === 0 ? "" : btccreditperiod,
          // btccreditperiod === 0
          //   ? 0
          //   : roomDetails?.btccreditperiod || btc_val?.value,
        btccreditperiodoption:
          btccreditperiodoption ||
          roomDetails?.btccreditperiodoption ||
          btc?.value,
        roomdetarr: roomdetarr || roomDetails?.roomdetarr,
        newDataSource: newDataSource || roomDetails?.newDataSource,
      });

      // setIsLoading(true);
      navigate("/photos");
    }
  };
  const onChangeautocomplete = (data, event) => {
    setClicked(false);

    setRoomType(data);
    setDataChange("You have unsaved data, do you want to continue?");
  };

  const handleKeyPress = (ev) => {
    setRoomType(ev.target.value);
  };
  const chkoutdtchge = (date, dateString) => {
    setClicked(false);
    setcheckoutdt(date._d);

    setEndValue(date);
    setValidityFrom(dateString);
    setDataChange("You have unsaved data, do you want to continue?");
  };
  const chkindtchge = (date, dateString) => {
    setClicked(false);
    setEndValue("");
    setStartValue(date);
    setcheckindt(date._d);
    setValidityFrom(dateString);

    let setdt = moment(date).year();
    let settmt = moment(date).month() + 12;
    let setday = moment(date).date() - 1;
    
    //if(Startvalue === ""){
    setEndValue(
      moment(setdt).set({ year: setdt, month: settmt, date: setday })
    );
    //}
    setValidityTo(moment(endValue).format("D-M-Y"));
    setDataChange("You have unsaved data, do you want to continue?");
  };
  const [selectedTags, setSelectedTags] = useState(["WIFI"]);
  const [selectedTagsmeal, setSelectedTagsmeal] = useState(["MAP"]);

  const handleChangetag = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);

    setInclusions(nextSelectedTags);
    setSelectedTags(nextSelectedTags);
    setDataChange("You have unsaved data, do you want to continue?");
  };

  const tagsDatainc = [
    "WIFI",
    "Airport transport",
    "Office transfer",
    "F & B discount ",
  ];
  const { Column } = Table;

  const handleChange = (value) => {
    setmealplan(value);
    setDataChange("You have unsaved data, do you want to continue?");
    setClicked(false);
  };

  const handleCancel = () => setPreviewOpen(false);
  const handleCancelmodal = () => {
    setOpenerror(false);
    setOpenerrorimg(false);
  }
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleChangephoto = ({ fileList: newFileList }) => {
    let fileextcheck = newFileList[0]?.name.split(".")
 
    if(fileextcheck !== undefined && (fileextcheck[1] == 'jpg' || fileextcheck[1] == 'jpeg' || fileextcheck[1] == 'png')) {
     setFileList(newFileList);
     setfile(newFileList[0]?.originFileObj);
    }
    else {
     if(newFileList?.length > 0)
     {
     setOpenerrorimg(true);
     }
     setmessagearrimg([ "* Supported file formats are (.jpg, .jpeg, .png)"]);
    setFileList([]);
    setfile([]);
    }
    setDataChange("You have unsaved data, do you want to continue?");
    setClicked(false);
    // cons
  };

  const uploadButton = (
    <div className="p-4" style={{ width: "500px", height: "100px" }}>
      <PlusOutlined />
      <div>
        <b>
          Room Image{" "}
          <small>
            <br />
            (.jpg, .jpeg, .png) <br /> (File size limit 2mb)
          </small>{" "}
          <span className="m-red">*</span>
        </b>
      </div>
    </div>
  );

  const handleDelete = (key) => {
    const newData = DataSource.filter((item) => item.key !== key);
    SetDataSource(newData);

    // addToRoomDetails({...roomDetails,roomdetarr:newData})

    changescheckingfunc("Room details updated");
  };
  const handleShow = () => {
    setmessagearr(["Please contact HB"]);
    setOpenerror(true);
  };

  const onChangeradiobtc = (e) => {
    setbtccreditperiod("");
    setbtccreditperiodoption(e.target.value);
    if (e.target.value == "Yes") {
      setshowbtcperiod(true);
      setbtccreditperiod(7);
    }
    if (e.target.value == "No") {
      setbtccreditperiod("");
      setshowbtcperiod(false);
    }
    changescheckingfunc("Room details updated");
    setDataChange("You have unsaved data, do you want to continue?");
  };

  const onChangertraffic = (e) => {
    settrafficoption(e.target.value);
    setDataChange("You have unsaved data, do you want to continue?");
    setClicked(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.scrollTo(0, 0);

    if (basic[0]?.propertyname === "" || basic[0]?.propertyname === undefined) {
      navigate("/");
    }
    if (roomDetails?.roomdetarr) {
      SetDataSource(roomDetails.roomdetarr);
    }
    if (roomDetails?.btccreditperiodoption === "Yes") {
      setshowbtcperiod(true);
    }
  }, []);

  const okpreviouspage = () => {
    navigate("/facilities");
  };
  const handleCancelerror = () => {
    setOpenprevious(false);
  };
  const showprevModal = () => {
    //do not delete
    //   let btc = document.querySelector(".btc");
    //   let room_type = document.querySelector("#message");
    //   let date_in = document.querySelector("#date_in");
    //   let singlerackrate = document.querySelector(".singlerackrate");
    //   let singlediscount = document.querySelector(".singlediscount");
    //   let doublerate = document.querySelector(".doublerate");
    //   let doublediscountrate = document.querySelector(".doublediscountrate");
    //   let meal_plan = document.querySelector(".mealplan >  div > span > input");
    //   let mealplan_input = document.querySelector(
    //     ".mealplan >  div > span.ant-select-selection-item"
    //   );
    //   let tac = document.querySelector(".tac");
    //   let prop_upload = document.querySelector(".prop_upload");

    //   if(JSON.stringify(DataSource) !== JSON.stringify(roomDetails?.roomdetarr))
    //   {
    //     setDataChange(" You have unsaved data, do you want to continue?");
    //   }

    //   if (
    //     (room_type?.value === "" || room_type?.value === undefined) &&
    //     (room_type?.value === "" || room_type?.value === undefined)  &&
    //     (date_in?.value === "" || date_in?.value === undefined|| date_in?.value === null) &&
    //      (singlerackrate?.value === "" || singlerackrate?.value === undefined|| singlerackrate?.value === null) &&
    //      (singlediscount?.value === "" || singlediscount?.value === undefined|| singlediscount?.value === null) &&
    //      (doublerate?.value === ""  || doublerate?.value === undefined|| doublerate?.value === null) &&
    //       (doublediscountrate?.value === "" ) &&
    //     mealplan_input?.innerHTML === "" && tac?.value === "" && fileList.length === 0 && (Inclusions?.length ===0 ||
    //       (Inclusions && Inclusions[1] ==='' ||Inclusions && Inclusions[1] ===undefined) && (JSON.stringify(DataSource) === JSON.stringify(roomDetails?.roomdetarr) || DataSource?.length === 0))

    //   )
    //   {
    //     setmessagearr([])
    //     navigate("/facilities");
    //     setOpenprevious(false);
    //   }
    //   else {

    //   if(dataChange !== '') {
    //     setmessagearr([dataChange]);
    //     setOpenprevious(true);
    //   }
    //   else
    //   {
    //     navigate("/facilities");
    //   }
    //   }
    navigate("/facilities");
  };
  const handlebtncancel = () => {
   // setopenerror(false);
    setOpenerrorimg(false);
  };


//   function textdotNum(s) {
//     console.log(s)

//     var rgx = /^[0-9]*\.?[0-9]*$/;
//     console.log(s.match(rgx))
//    // return s.match(rgx);
// }

// function textdotNum(s) {
//   console.log(s);

//   // Allow digits and a dot, but only if there is at most one dot
//   var rgx = /^[0-9]*(\.[0-9]*)?$/;
//   console.log(s.match(rgx));
//   // If you want to get the matched value, you can use the RegExp exec method
//   var matchResult = rgx.exec(s);
//   console.log(matchResult ? matchResult[0] : null);

//   // If you want to replace non-digit characters, you can do something like this:
//   var cleanedString = s.replace(/\D/g, '');
//   console.log(cleanedString);

//   // Return the cleaned string or the matched value depending on your use case
//   return cleanedString;
// }

// function textdotNum(s) {
//   // Allow digits and a dot, but only if there is at most one dot
//   var rgx = /^\d*\.?\d*$/;

//   // If you want to get the matched value, you can use the RegExp exec method
//   var matchResult = rgx.exec(s);

//   // If a valid match is found, return the matched value, otherwise return null
//   return matchResult ? matchResult[0] : null;
// }

function textdotNum(s) {
  // Replace non-numeric characters (except the first dot) with an empty string
  return s.replace(/[^\d.]|(?<=\..*)\./g, '');
}
  return (
    <div>
      {isLoading && (
        <div className="loaderloading">
          <div className="example">
            <Spin />{" "}
            <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
              Loading ...
            </span>
          </div>
        </div>
      )}
      <nav className="row">
        <a className="navbar-brand col-md-2 text-center mobile-show" href="#">
          <img
            className=""
            src={logo_img}
            alt=""
            width={"120px"}
            height={"85px"}
          />
        </a>

        <span className="text-center col-md-12 d-flex justify-content-center align-items-center">
          <h3>
            <b>Property Onboarding</b>
          </h3>
        </span>
      </nav>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-md-2 no-border-l h100"
            style={{ background: "#fff", borderLeft: "none" }}
          >
            <Card className=" col-md-12 cursor-not-allowed h-100 side-menu">
              <Steps
                size="small"
                className="mt-md-5 mobile-hide"
                direction="vertical"
                current={3}
              >
                <Steps.Step title={<NavLink to="/">Basics</NavLink>} />
                <Steps.Step
                  title={<NavLink to="/location">Location</NavLink>}
                />
                <Steps.Step
                  title={<NavLink to="/facilities">Facilities</NavLink>}
                />
                <Steps.Step
                  title={<NavLink to="/roomdet">Room details</NavLink>}
                />
                <Steps.Step title={<NavLink to="/photos">Photos</NavLink>} />
                <Steps.Step title={<NavLink to="/Profile">Contact</NavLink>} />
                <Steps.Step title={<NavLink to="/Publish">Publish</NavLink>} />
              </Steps>
              <a
                className="navbar-brand col-md-2 text-center logo-display"
                href="#"
              >
                <img className="logo_name" src={logo_img} alt="" width="100%" />
              </a>
              <p className="mt-sm-3" style={{ fontSize: "12px" }}>
                <span
                  className="fw-bold"
                  style={{ fontSize: "14px", marginBottom: "10px" }}
                >
                  Supported Browsers :{" "}
                </span>
                <br />{" "}
                <span style={{ margin: "0px", lineHeight: "10px" }}></span>1.
                Google Chrome,
                <br />
                2. Microsoft Edge,
                <br />
                3. Firefox.
              </p>
            </Card>
            {/* <div className='d-flex justify-content-center'> */}
          </div>
          <div
            className="col-md-10 roomdet layout-ml"
            style={{ background: "rgb(227 245 255)" }}
          >
            <div className="row ">
              <div className="col-sm-6 p-3">
                <h3>Setup your rooms.</h3>
                <p>
                  Choose your nightly room rates based on guests and other
                  conditions.
                </p>
              </div>
              <div className="col-sm-6 mt-2">
                <img src={roomheadpic} alt="" height={"120px"} />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 p-3">
                <h5>Rooms Details</h5>
                <p>You can create one or more room type at this stage.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-9">
                <h5>BTC / Credit Period</h5>
                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    minHeight: "57px",
                    display: "flex",
                  }}
                  className="asn-center"
                >
                  <div className="row">
                    <div className="col-lg-12 pe-0 d_f_a_c">
                      <Radio.Group
                        onChange={onChangeradiobtc}
                        value={btccreditperiodoption}
                        className="float-start col-2 col-md-3"
                      >
                        <Radio value={"Yes"}>
                          <b>Yes</b>
                        </Radio>

                        <Radio value={"No"}>
                          <b>No</b>
                        </Radio>
                      </Radio.Group>
                      {((btccreditperiodoption === "Yes" &&
                        showbtcperiod === true) ||
                        (roomDetails?.btccreditperiodoption === "Yes" &&
                          showbtcperiod === true)) && (
                        <div className="col-lg-9 ps-0 float-start">
                          <div className="row">
                            <div className="col-3 col-sm-3 col-lg-3">
                              {/* <Input
                                type="text"
                                maxLength={2}
                                min={1}
                                className="mt-sm-0 mt-1 w-100 btc"
                                style={{ width: "150px" }}
                              
                                onChange={e => {setbtccreditperiod(textNum(e));
                                  changescheckingfunc("Room details updated");
                                  setDataChange("You have unsaved data, do you want to continue?");
                                }}
                                value={btccreditperiod}
                              ></Input> */}

                              <Select
                                // className="mealplan"
                                className="mt-sm-0 mt-1 w-100 btc"
                                style={{ width: "150px" }}
                                placeholder="7"
                                defaultValue="7"
                                // style={{ width: "100%" }}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  setbtccreditperiod(e);
                                  changescheckingfunc("Room details updated");
                                  setDataChange(
                                    "You have unsaved data, do you want to continue?"
                                  );
                                }}
                                value={btccreditperiod}
                                options={[
                                  {
                                    value: "7",
                                    label: "7",
                                  },
                                  {
                                    value: "10",
                                    label: "10",
                                  },
                                  {
                                    value: "15",
                                    label: "15",
                                  },
                                  {
                                    value: "30",
                                    label: "30",
                                  },
                                  {
                                    value: "45",
                                    label: "45",
                                  },
                                ]}
                              />
                            </div>

                            <span className="col-9 pt-2 pt-md-1 ps-0 ps-sm-0 col-sm-9">
                              days from invoice submission{" "}
                              <span className="m-red">*</span>
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-12 col-lg-9">
                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  <div className="row">
                    <div className="col-sm-4 l_city">
                      <label>
                        <b>
                          Room Type&nbsp;<span className="m-red">*</span>
                        </b>
                      </label>
                      <AutoComplete
                        options={options}
                        style={{
                          width: "100%",
                        }}
                        id="message"
                        name="Roomtype"
                        value={RoomType}
                        onChange={onChangeautocomplete}
                        onKeyUp={handleKeyPress}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        placeholder="Room Type"
                        className="prop_roomtype"
                      />
                    </div>

                    <div className="col-sm-4">
                      <label>
                        <b>
                          Validity From&nbsp;<span className="m-red">*</span>
                        </b>
                      </label>
                      <br />
                      <DatePicker
                        value={Startvalue}
                        format="DD-MM-YYYY"
                        onChange={chkindtchge}
                        style={{ width: "100%" }}
                        className="prop_indate"
                        placeholder="Validity From"
                        id="date_in"
                      />
                    </div>
                    <div className="col-sm-4">
                      <label>
                        <b>Validity To</b>
                      </label>
                      <br />
                      <DatePicker
                        value={endValue}
                        format="DD-MM-YYYY"
                        id="date_out"
                        onChange={chkoutdtchge}
                        style={{ width: "100%" }}
                        className="prop_outdate"
                        placeholder="Validity To"
                        // disabledDate={(current) => {
                        //   let customDate = moment(Startvalue).format("YYYY-MM-DD");
                        //  // let customDate1 = moment(endValue).format("YYYY-MM-DD");
                        //   return (current && current < moment(customDate, "YYYY-MM-DD"));
                        // }}
                        disabledDate={(d) =>
                          !d ||
                          d.isAfter(moment(endValue).format("YYYY-MM-DD")) ||
                          d.isSameOrBefore(
                            moment(Startvalue).format("YYYY-MM-DD"),
                            "day"
                          )
                        }
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            {/* <div className="col-sm-3 p-4"> */}
            <div className="row mt-2">
              <div className="col-sm-4 col-lg-3">
                <Card
                  // title="Single Tariff"
                  bordered={true}
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  <label className="w-100">
                    <b>
                      Single Rack Rate&nbsp;<span className="m-red">*</span>
                    </b>
                    {/* <span className="float-end discounthelptext" title="fsdsd">help?</span> */}
                  </label>
                  <Input
                    placeholder="Single Rack Rate"
                    type="text"
                    min={3}
                    maxLength={5}
                    value={SingleRackRate}
                    onChange={(e) => {
                      setSingleRackRate(textNum(e));
                      setDataChange(
                        "You have unsaved data, do you want to continue?"
                      );
                      setClicked(false);
                    }}
                    className="singlerackrate"
                  ></Input>
                  <br />
                  <label className="mt-md-1">
                    <b>
                      Single Discount Rate&nbsp;<span className="m-red">*</span>
                    </b>
                  </label>
                  <Input
                    className="mt-1 mt-sm-0 singlediscount"
                    minLength={3}
                    min={3}
                    maxLength={5}
                    type="text"
                    onChange={(e) => {
                      setSingleDiscountRate(
                        parseInt(SingleRackRate) === 0 ? 0 : textNum(e)
                      );
                      setDataChange(
                        "You have unsaved data, do you want to continue?"
                      );
                      setClicked(false);
                    }}
                    value={
                      parseInt(SingleRackRate) === 0 ? 0 : SingleDiscountRate
                    }
                    placeholder="Single Discount Rate"
                  />
                  <label className="mt-md-1">
                    <b>
                      Tariff Include Taxes&nbsp;<span className="m-red">*</span>
                    </b>
                  </label>
                  <br />
                  <Radio.Group
                    onChange={onChangertraffic}
                    value={trafficoption}
                    className="float-start tarifplan"
                  >
                    <Radio value={"Yes"}>
                      <b>Yes</b>
                    </Radio>

                    <Radio value={"No"}>
                      <b>No</b>
                    </Radio>
                  </Radio.Group>
                </Card>
              </div>
              <div className="col-sm-4 col-lg-3 ps-sm-3 mt-2 mt-sm-0">
                <Card
                  // title="Double Tariff"
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  <label className="w-100">
                    <b>
                      Double Rack Rate&nbsp;<span className="m-red">*</span>
                      
                    </b>
                    {/* <span className="float-end discounthelptext" title="fsdsd">help?</span> */}
                  </label>
                  <Input
                    placeholder="Double Rack Rate"
                    minLength={3}
                    min={3}
                    maxLength={5}
                    value={DoubleRackRate}
                    type="text"
                    onChange={(e) => {
                      setDoubleRackRate(textNum(e));
                      setDataChange(
                        "You have unsaved data, do you want to continue?"
                      );
                      setClicked(false);
                    }}
                    className="doublerate"
                  ></Input>
                  <br />
                  <label className="mt-md-1">
                    <b>
                      Double Discount Rate&nbsp;<span className="m-red">*</span>
                    </b>
                  </label>
                  <Input
                    className="mt-1 mt-sm-0 doublediscountrate"
                    minLength={3}
                    min={3}
                    maxLength={5}
                    value={
                      parseInt(DoubleRackRate) === 0 ? 0 : DoubleDiscountRate
                    }
                    type="text"
                    onChange={(e) => {
                      setDoubleDiscountRate(
                        parseInt(DoubleRackRate) === 0 ? 0 : textNum(e)
                      );
                      setDataChange(
                        " You have unsaved data, do you want to continue?"
                      );
                      setClicked(false);
                    }}
                    placeholder="Double Discount Rate"
                  />
                  <label className="mt-md-1">
                    <b>
                      Tariff Include Taxes&nbsp;<span className="m-red">*</span>
                    </b>
                  </label>
                  <br />
                  <Radio.Group
                    onChange={onChangertraffic}
                    value={trafficoption}
                    className="float-start tarifplan"
                  >
                    <Radio value={"Yes"}>
                      <b>Yes</b>
                    </Radio>

                    <Radio value={"No"}>
                      <b>No</b>
                    </Radio>
                  </Radio.Group>
                </Card>
              </div>
              <div className="col-sm-4 col-lg-3 ps-sm-3 mt-2 mt-sm-0">
                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  <label>
                    <b>Meal Plan</b>
                  </label>
                  <br />
                  <Select
                    className="mealplan"
                    placeholder="Meal Plan"
                    defaultValue="EP-Room only"
                    style={{ width: "100%" }}
                    onChange={handleChange}
                    value={mealplan}
                    options={[
                      {
                        value: "CP - Breakfast",
                        label: "CP - Breakfast",
                      },
                      {
                        value: "EP - Room only",
                        label: "EP - Room only",
                      },
                      {
                        value: "MAP - Breakfast, Lunch or Dinner",
                        label: "MAP - Breakfast, Lunch or Dinner",
                      },
                      {
                        value: "AP - Breakfast, Lunch, Dinner",
                        label: "AP - Breakfast, Lunch, Dinner",
                      },
                    ]}
                  />

                  <br />
                  <label className="mt-md-1">
                    <b>TAC - Travel Agent Commission (%)</b>
                  </label>
                  <Input
                    placeholder="TAC %"
                    type="text"
                    onChange={(e) => {
                     // setTacper(e.target.value);
                     setTacper(
                        textdotNum(e.target.value)
                      );
                      setDataChange(
                        " You have unsaved data, do you want to continue?"
                      );
                      setClicked(false);
                    }}
                    value={Tacper}
                    max={50}
                    min={1}
                    maxLength={5}
                    className="mb-sm-1 tac"
                    // onInput={percentagevalidate}
                    onInput={propInput1}
                  />
                </Card>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-sm-12 col-lg-9 inclusions">
                <div className="row">
                  <div className="col-6 col-sm-4">
                    <Card
                      //title="Inclusions *"
                      style={{
                        background: "#f4f4f4",
                        boxShadow: "rgb(207 202 202) 0px 0px 10px",
                      }}
                    >
                      <div className="col-sm-12">
                        <label className="w-100">
                          <b>
                            Inclusions&nbsp;<span className="m-red">*</span>
                          </b>
                        </label>
                        {tagsDatainc.map((tag) => (
                          <CheckableTag
                            key={tag}
                            checked={selectedTags.indexOf(tag) > -1}
                            onChange={(checked) => {
                              handleChangetag(tag, checked);
                              setClicked(false);
                            }}
                            // style={{width:"50px"}}
                            className="w-100"
                            value={Inclusions}
                          >
                            <li style={{ display: "inline" }} className="w-100">
                              {"> " + tag}
                            </li>
                          </CheckableTag>
                        ))}
                      </div>
                    </Card>
                  </div>
                  <div className="col-6 col-sm-8">
                    <div className="row h-100">
                      <div className="col-12 col-sm-6 f-height-o">
                        <form id="formimg" action="#" className="f-height-o">
                          <Upload
                            // action="https://www.mocky.io/v2/5cc8019d300000980a0f55e76"
                            listType="picture-card"
                            fileList={fileList}
                            // action={"http://localhost:8000/"}
                            onPreview={handlePreview}
                            onChange={handleChangephoto}
                            multiple={false}
                            accept={".jpg, .jpeg, .png"}
                            beforeUpload={(file) => {
                              const isLt2M = file.size / 1024 / 1024 < 2;
                              if (!isLt2M) {
                                seterrorphotofilesize(true);
                                return true;
                              } else {
                                seterrorphotofilesize(false);
                                return false;
                              }
                            }}
                            className="f-height prop_upload prop_upload_error"
                          >
                            {fileList.length >= 1 ? null : uploadButton}
                          </Upload>
                        </form>
                      </div>
                      <Modal
                        open={previewOpen}
                        title={previewTitle}
                        footer={null}
                        onCancel={handleCancel}
                      >
                        <img
                          alt="example"
                          style={{
                            width: "100%",
                          }}
                          src={previewImage}
                          maskClosable={false}
                        />
                      </Modal>
                      <div className="col-sm-4 mt-2 mt-sm-0 text-center roomdet-add ">
                        <Button
                          type="primary"
                          className="mt-sm-5 addlist"
                          style={{ minWidth: "100px" }}
                          onClick={handleClick}
                          //disabled={clicked}
                        >
                          Add room details{" "}
                          <span className="desktop_show">
                            &nbsp;to the list
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <NavLink to="/facilities"></NavLink> */}

              {/* <NavLink to="/photos"></NavLink> */}
            </div>
            <div className="row mt-2">
              <div className="col-sm-12 col-lg-9">
                <Table
                  dataSource={DataSource}
                  scroll={{
                    x: 1300,
                  }}
                  pagination={false}
                >
                  <Column
                    title={`Room Type`}
                    key="RoomType"
                    render={(_, record) => (
                      <Space size="middle">
                        <p>{record?.RoomType}</p>
                      </Space>
                    )}
                    id="room_type_room_details"
                  />
                  <Column
                    title="Validity From"
                    key="ValidityFrom"
                    render={(_, record) => (
                      <Space size="middle">
                        <p style={{ width: "100px" }}>{record?.ValidityFrom}</p>
                      </Space>
                    )}
                    style={{ width: "85px" }}
                  />
                  <Column
                    title="Validity To"
                    key="ValidityTo"
                    render={(_, record) => (
                      <Space size="middle">
                        <p style={{ width: "100px" }}>{record?.ValidityTo}</p>
                      </Space>
                    )}
                    style={{ width: "85px" }}
                  />

                  <Column
                    title="Single Discount Rate"
                    key="SingleDiscountRate"
                    render={(_, record) => (
                      <Space size="middle">
                        <span
                          style={{
                            width: "60px",
                            textAlign: "right",
                            display: "block",
                          }}
                        >
                          {record?.SingleDiscountRate}
                        </span>
                      </Space>
                    )}
                  />

                  <Column
                    title="Double Discount Rate"
                    key="DoubleDiscountRate"
                    render={(_, record) => (
                      <Space size="middle">
                        <span
                          style={{
                            width: "60px",
                            textAlign: "right",
                            display: "block",
                          }}
                        >
                          {record?.DoubleDiscountRate}
                        </span>
                      </Space>
                    )}
                  />
                  <Column
                    title="Tariff Include Taxes"
                    key="Tariffincludetaxes"
                    render={(_, record) => (
                      <Space size="middle">
                        <p>{record?.trafficoption}</p>
                      </Space>
                    )}
                  />

                  
                  <Column
                    title="TAC %"
                    key="Tacper"
                    render={(_, record) => (
                      <Space size="middle">
                        <span
                          style={{
                            width: "40px",
                            textAlign: "right",
                            display: "block",
                          }}
                        >
                          {record?.Tacper}
                        </span>
                      </Space>
                    )}
                  />
                  <Column
                    title="Meal Plan"
                    key="Meal Plan"
                    render={(_, record) => (
                      <Space size="middle">
                        <p>{record?.MealPlan}</p>
                      </Space>
                    )}
                  />
                  <Column
                    title="Inclusions "
                    key="Inclusions"
                    render={(_, record) => (
                      <Space size="middle">
                        <p>
                          {" "}
                          {record.Inclusions.map((item, index) =>
                            index < record.Inclusions.length - 1
                              ? item + ", "
                              : item
                          )}
                        </p>
                      </Space>
                    )}
                  />
                  <Column
                    title="Room Image"
                    key="RoomImagepreview"
                    render={(_, record) => (
                      <Space size="middle">
                        {/* <p>{record?.RoomImagepreview}</p> */}
                        {/* <img src={record?.RoomImagepreview} /> */}
                        <p>{record?.RoomImagename}</p>
                      </Space>
                    )}
                  />
                  <Column
                    title="Action"
                    key="delete"
                    // render={(_, record) => (
                    //   <Space size="middle">

                    //   </Space>
                    // )}

                    render={(_, record) =>
                      DataSource.length >= 1 ? (
                        <Popconfirm
                          title="Sure to delete?"
                          onConfirm={() =>
                            record?.isDelete !== false &&
                            handleDelete(record?.key)
                          }
                          okText={"Yes"}
                          cancelText={"No"}
                          className="delete_list"
                          disabled={record?.isDelete === false && true}
                        >
                          <a
                            className="btn btn-danger btn-sm text-white d_v_c"
                            onClick={() =>
                              record?.isDelete === false && handleShow()
                            }
                            style={{
                              width: "30px",
                              opacity: record?.isDelete === false ? 0.5 : 1,
                              cursor:
                                record?.isDelete === false && "not-allowed",
                            }}
                          >
                            <DeleteOutlined />
                          </a>
                        </Popconfirm>
                      ) : null
                    }
                  />
                </Table>
                <div className="col-sm-12 pb-sm-5">
                  <div className="float-end mt-3 mt-sm-3 mb-4">
                    {/* <Button
                      type="primary"
                      style={{ minWidth: "100px", marginRight: "5px" }}
                    >
                      <NavLink to="/facilities">Previous</NavLink>
                    </Button> */}
                    <Button
                      type="primary"
                      style={{ minWidth: "100px", marginRight: "5px" }}
                      onClick={showprevModal}
                    >
                      {/* <NavLink to="/" onClick={showprevModal}>Previous</NavLink> */}
                      Previous
                    </Button>
                    <Button
                      type="primary"
                      className="ms-sm-3"
                      style={{ minWidth: "100px" }}
                      onClick={Nextpagerouting}
                    >
                      Save & Continue
                      {/* <NavLink to="/photos" ></NavLink> */}
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <Modal
              className="error_msg"
              title="Error message"
              open={Openeror}
              onCancel={handleCancelmodal}
              footer={[
                <Button key="back" type="primary" onClick={handleCancelmodal}>
                  Close
                </Button>,
              ]}
              maskClosable={false}
            >
              {errormessagearr &&
                errormessagearr.map((item, index) => <p key={index}>{item}</p>)}
            </Modal>
            <Modal
        className="error_msg"
        title="Error message"
        open={Openerorimg}
        onCancel={handleCancelmodal}
        footer={[
          <Button
            key="back2"
            type="primary"
            className="dblclick"
            onClick={handleCancelmodal}
          >
            Close
          </Button>,
        ]}
        maskClosable={false}
      >
        {messagearrimg &&
                messagearrimg.map((item, index) => <p key={index}>{item}</p>)}
      </Modal>
            <Modal
              className="error_msg"
              title="Error message"
              open={openprevious}
              onCancel={handleCancelerror}
              footer={[
                <Button key="back" type="primary" onClick={okpreviouspage}>
                  Close
                </Button>,
                <Button key="back1" type="primary" onClick={handleCancelerror}>
                  Cancel
                </Button>,
              ]}
              maskClosable={false}
            >
              You have un saved data, do you want to continue?
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

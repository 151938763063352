import React, { useCallback, useContext } from "react";
import facilitypic from "../assets/ec-amenities@2x.png";
import { Card, Steps, Button, Modal, Checkbox, Col, Row,Spin } from "antd";
import "../styles/propinfo.css";
import "antd/dist/antd.css";
import pic from "../assets/unnamed.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";

export default function Facilities() {
  const { facilities, addToFacilities, changescheckingfunc,basic,logo_img } =
    useContext(PropertyContext);
  const [safetyAmenities, setsafetyAmenities] = useState(
    facilities[0]?.safetyAmenities
  );
  const [CleaninessAmenities, setcleaninessAmenities] = useState(
    facilities[0]?.CleaninessAmenities
  );
  const [otherinformation, setotherinformation] = useState(
    facilities[0]?.otherinformation
  );
  const [isLoading, setIsLoading] = useState(false);
  const [dataChange, setDataChange] = useState("");
  const [openprevious, setOpenprevious] = useState("");
  const [errormessagearr, setmessagearr] = useState([]);
  const [Openeror, setOpenerror] = useState(false);

  const safety = [
    "Women safety and friendly",
    "CCTV surveillance",
    "Contactless check-in / check-out",
    "Security guard",
    "Fire extinguisher",
    "Emergency exit",
  ];
  const Cleanliness = [
    "Hygienic towels and blanket",
    "Free face masks",
    "Free of pests",
    "Cleaned by professional cleaning team",
    "Hand sanitizer",
    "Water and electricity",
  ];
  const Other_info = [
    "Laundry services",
    "Cyber cafe / Wifi",
    "Conference hall",
    "Restaurtant services",
    "Swimming pool services",
    "Gym services",
    "Tour assistance",
    "Pet friendly",
    "Parking",
  ];

  const options = [
    {
      value: "Burns Bay Road",
    },
    {
      value: "Downing Street",
    },
    {
      value: "Wall Street",
    },
  ];
  const navigate = useNavigate();

  const onChange = (checkedValues) => {
    setsafetyAmenities(checkedValues);
    changescheckingfunc("Facilities details updated");
    setDataChange(" You have unsaved data, do you want to continue?");
  };

  useEffect(() => {
    if(basic[0]?.propertyname ==='' || basic[0]?.propertyname ===undefined) {
    navigate("/")
      }
    window.scrollTo(0, 0)
  },[]);

  const onChangecleaniness = useCallback(
    (checkedValues) => {
      setcleaninessAmenities(checkedValues);
      changescheckingfunc("Facilities details updated");
      setDataChange(" You have unsaved data, do you want to continue?");
    },
    [CleaninessAmenities]
  );

  const onChangeotherInformation = (checkedValues) => {
    setotherinformation(checkedValues);
    changescheckingfunc("Facilities details updated");
    setDataChange(" You have unsaved data, do you want to continue?");
  };
  
  const showModal = () => {
    //do not delete
    //let result1 = safety.map(item => safetyAmenities.includes(item))
    //do not delete
    //Safety checked box
    let safety_result = safety.map((item, i) => {
      // var obj = {};

      if (safetyAmenities?.includes(item)) {
        // obj[item] = 1;
        return 1;
      } else {
        return 0;
      }
      // obj[item] = 0;}
      //  return obj;
    });

    //Cleanliness checked box
    let Cleanliness_result = Cleanliness.map((item, i) => {
      // var obj = {};

      if (CleaninessAmenities?.includes(item)) {
        // obj[item] = 1;
        return 1;
      } else {
        return 0;
        // obj[item] = 0;
      }
      //  return obj;
    });
    //Other infomation checked box
    let Other_info_result = Other_info.map((item, i) => {
      // var obj = {};

      if (otherinformation?.includes(item)) {
        // obj[item] = 1;
        return 1;
      } else {
        // obj[item] = 0;
        return 0;
      }
      //  return obj;
    });setmessagearr([]);

   // if((safetyAmenities?.length === 0 || safetyAmenities===undefined || facilities[0]?.safetyAmenities?.length === 0) ){
    if((safetyAmenities?.length === 0) ){

      setmessagearr(pre => [...pre,"* Minimum one safety amenities required"]);

    }
   // if((CleaninessAmenities?.length === 0 ||CleaninessAmenities === undefined  || facilities[0]?.CleaninessAmenities?.length === 0)){
    if((CleaninessAmenities?.length === 0)){
      
      setmessagearr(pre => [...pre,"* Minimum one cleanliness amenities required"]);

    }
  

   // if((safetyAmenities?.length === 0  || safetyAmenities===undefined || facilities[0]?.safetyAmenities?.length === 0) || (CleaninessAmenities?.length === 0 || CleaninessAmenities === undefined ||facilities[0]?.CleaninessAmenities?.length === 0) ) 
    if(safetyAmenities?.length === 0 || CleaninessAmenities?.length === 0) 
    {
      setOpenerror(true);
    }
    else {
      setIsLoading(true);
      addToFacilities([
        {
          safetyAmenities: safetyAmenities || facilities[0]?.safetyAmenities,
          CleaninessAmenities:
            CleaninessAmenities || facilities[0]?.CleaninessAmenities,
          otherinformation: otherinformation || facilities[0]?.otherinformation,
          safety_check_result: safety_result,
          Cleanliness_check_result: Cleanliness_result,
          Other_info_check_result: Other_info_result,
        },
      ]);
      setmessagearr([]);
  
      return navigate("/roomdet");
    }

   

  };
  const okpreviouspage = () => {
    navigate("/location");
  };



  const showprevModal = () => {
    // if (
    //   (safetyAmenities?.length === 0 ||
    //     safetyAmenities === undefined ||
    //     safetyAmenities === null) &&
    //   (CleaninessAmenities?.length === 0 ||
    //     CleaninessAmenities === undefined ||
    //     CleaninessAmenities === null) &&
    //   (otherinformation?.length === 0 ||
    //     otherinformation === undefined ||
    //     otherinformation === null) || 
    //     (JSON.stringify(facilities[0]?.safetyAmenities?.sort()) === JSON.stringify(safetyAmenities?.sort()) &&
    //     JSON.stringify(facilities[0]?.CleaninessAmenities?.sort()) === JSON.stringify(CleaninessAmenities?.sort()) &&
    //     JSON.stringify(facilities[0]?.otherinformation?.sort()) === JSON.stringify(otherinformation?.sort()) )
    // ) {
    //   navigate("/location");
    // } else {
    //  
    //   if (dataChange !== "") {
    //     setmessagearr([dataChange]);
    //     setOpenprevious(true);
    //   } else {
    //     navigate("/location");
    //   }
    // }
    navigate("/location");
  };
  const handleCancelerror = () => {
    setOpenprevious(false);
  };
  const handleCancelmodal = () => setOpenerror(false);
  return (
    <div>
       {isLoading && (
        <div className="loaderloading">
          <div className="example">
            <Spin />{" "}
            <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
              Loading ...
            </span>
          </div>
        </div>
      )}
      <nav className="row">
        <a className="navbar-brand col-md-2 text-center mobile-show" href="#">
          <img className="" src={logo_img} alt="" width={"120px"} height={"85px"} />
        </a>

        <span className="text-center col-md-12 d-flex justify-content-center align-items-center">
          <h3>
            <b>Property Onboarding</b>
          </h3>
        </span>
      </nav>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-md-2 no-border-l h100"
            style={{ background: "#fff", borderLeft: "none" }}
          >
            <Card className=" col-md-12 h-100 side-menu no-border-l">
              <Steps
                size="small"
                className="mt-md-5 cursor-not-allowed mobile-hide"
                direction="vertical"
                current={2}
              >
                <Steps.Step title={<NavLink to="/">Basics</NavLink>} />
                <Steps.Step
                  title={<NavLink to="/location">Location</NavLink>}
                />
                <Steps.Step
                  title={<NavLink to="/facilities">Facilities</NavLink>}
                />
                <Steps.Step
                  title={<NavLink to="/roomdet">Room details</NavLink>}
                />
                <Steps.Step title={<NavLink to="/photos">Photos</NavLink>} />
                <Steps.Step title={<NavLink to="/Profile">Contact</NavLink>} />
                <Steps.Step title={<NavLink to="/Publish">Publish</NavLink>} />
              </Steps>
              <a
                className="navbar-brand col-md-2 text-center logo-display"
                href="#"
              >
                <img className="logo_name" src={logo_img} alt="" width="100%" />
              </a>
              <p className="mt-sm-3 desk_browser" style={{fontSize: "12px"}}><span className="fw-bold" style={{fontSize: "14px",marginBottom:"10px"}}>Supported Browsers : </span><br/> <span style={{margin:"0px",lineHeight:"10px"}}></span>1. Google Chrome,<br/>2. Microsoft Edge,<br/>3. Firefox.</p>
            </Card>
          </div>
          <div
            className="col-md-10 bgcolor facilities layout-ml"
            style={{ background: "rgb(227 245 255)" }}
          >
            <div className="row">
              <div className="col-sm-6 p-3">
                <h3>What’s unique and wonderful about your property?</h3>
                <p>
                  Every room and home is unique. Tell us why yours stands out.
                </p>
              </div>
              <div className="col-sm-6 mt-2">
                <img src={facilitypic} alt="" height={"120px"} />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-9">
                <h5>Safety&nbsp;<span className="m-red">*</span></h5>
                <p>
                  Show guests that you take their safety and health seriously
                  with these amenities.
                </p>
                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  <Checkbox.Group
                    style={{
                      width: "100%",
                    }}
                    onChange={onChange}
                    value={safetyAmenities}
                  >
                    <Row>
                      {safety.map((item, index) => (
                        <Col key={index} xs={24} sm={12} lg={8}>
                          <Checkbox  value={item}>
                            {item}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Card>

                <h5>Cleanliness&nbsp;<span className="m-red">*</span></h5>
                <p>
                  Show guests that you take their safety and health seriously
                  with these amenities.
                </p>
                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  <Checkbox.Group
                    style={{
                      width: "100%",
                    }}
                    onChange={onChangecleaniness}
                    value={CleaninessAmenities}
                  >
                    <Row>
                      {Cleanliness.map((item, index) => (
                        <Col key={index} xs={24} sm={12} lg={8}>
                          <Checkbox value={item}>{item}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Card>
                <h5>Other Information</h5>
                <p>
                  These offerings are found in most of our successful
                  properties.
                </p>
                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  <Checkbox.Group
                    style={{
                      width: "100%",
                    }}
                    onChange={onChangeotherInformation}
                    value={otherinformation}
                  >
                    <Row>
                      {Other_info.map((item, index) => (
                        <Col key={index} xs={24} sm={12} lg={8}>
                          <Checkbox value={item}>{item}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Card>

                <div className="float-end mt-3 mt-sm-3 mb-4">
                  <Button
                    type="primary"
                    style={{ minWidth: "100px", marginRight: "5px" }}
                    onClick={showprevModal}
                  >
                    {/* <NavLink to="/" onClick={showprevModal}>Previous</NavLink> */}
                    Previous
                  </Button>
                  <Button
                    type="primary"
                    className="ms-sm-3"
                    style={{ minWidth: "100px" }}
                    onClick={showModal}
                  >
                    Save & Continue
                    {/* <NavLink to="/roomdet"> </NavLink> */}
                  </Button>
                </div>

                {/* "jquery": "^3.6.0", */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
              className="error_msg"
              title="Error message"
              open={Openeror}
              onCancel={handleCancelmodal}
              footer={[
                <Button key="back" type="primary" onClick={handleCancelmodal}>
                 Close
                </Button>,
              ]}
              maskClosable={false}
            >
              {errormessagearr &&
                errormessagearr.map((item, index) => <p key={index}>{item}</p>)}
            </Modal>
      <Modal
        className="error_msg"
        title="Error message"
        open={openprevious}
        onCancel={handleCancelerror}
        footer={[
          <Button key="back" type="primary" onClick={okpreviouspage}>
           Close
          </Button>,
          <Button key="back1" type="primary" onClick={handleCancelerror}>
            Cancel
          </Button>,
        ]}
        maskClosable={false}
      >
        You have unsaved data, do you want to continue?
      </Modal>
    </div>
  );
}

// import React from "react";
// import usePlacesAutocomplete, {
//   getGeocode,
//   getLatLng
// } from "use-places-autocomplete";
// import useOnclickOutside from "react-cool-onclickoutside";
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// // import { useForm, Controller } from "react-hook-form";
// function Search() {
//   // const { handleSubmit, control } = useForm();
//   // const [stuff, setData] = useState([]);

//   const {
//     ready,
//     value,
//     suggestions: { status, data },
//     setValue,
//     clearSuggestions
//   } = usePlacesAutocomplete({
//     requestOptions: {
//       /* Define search scope here */
//     },
//     debounce: 300
//   });
//   const ref = useOnclickOutside(() => {
//     // When user clicks outside of the component, we can dismiss
//     // the searched suggestions by calling this method
//     clearSuggestions();
//   });

//   const handleInput = (e) => {
//     // Update the keyword of the input element
//     setValue(e.target.value);
//   };

//   const handleSelect = ({ description }) => () => {
//     // When user selects a place, we can replace the keyword without request data from API
//     // by setting the second parameter to "false"
//     setValue(description, false);
//     clearSuggestions();

//     // Get latitude and longitude via utility functions
//     getGeocode({ address: description })
//       .then((results) => getLatLng(results[0]))
//       .then(({ lat, lng }) => {
        
//         console.log("📍 Coordinates: ", { lat, lng });
//         { <label  class="form-label">{ lat}{ lng }</label>}
//       })
//       .catch((error) => {
//         console.log("😱 Error: ", error);
//       });
//   };

//   const renderSuggestions = () =>
//     data.map((suggestion) => {
//       const {
//         place_id,
//         structured_formatting: { main_text, secondary_text }
//       } = suggestion;

//       return (
//         <ul class="list-group">
//         <li className="list-group-item recommendelist" key={place_id} onClick={handleSelect(suggestion)}>
//           <strong>{main_text}</strong> <small>{secondary_text}</small>
//         </li>
//         </ul>
//       );
//     });

//   return (
//     <div ref={ref}>
//       <input
//         value={value}
//         onChange={handleInput}
//         disabled={!ready}
//         name="address"
//         placeholder="Search location"
//       />
//       {status === "OK" && <ul>{renderSuggestions()}</ul>}
//     </div>
//   );
// }
// export default Search;
import React, { useState,useEffect } from 'react';
import 'antd/dist/antd.css';

import { Select } from 'antd';
import axios from 'axios' 
const statedetails=['tamil Nadu','karnataka']
const provinceData = ['Zhejiang', 'Jiangsu'];
const cityData = {
  Zhejiang: ['Hangzhou', 'Ningbo', 'Wenzhou'],
  Jiangsu: ['Nanjing', 'Suzhou', 'Zhenjiang'],
};
const Search = () => {
  // const { Option } = Select;
  const [State, setState] = useState([]);
  const [City,setCity]=useState([])
  // const [secondCity, setSecondCity] = useState(cityData[provinceData[0]][0]);
  // const handleProvinceChange = (value) => {
  //   setCities(cityData[value]);
  //   setSecondCity(cityData[value][0]);
  // };
  // const onSecondCityChange = (value) => {
  //   setSecondCity(value);
  // };



  useEffect(() => {
    const stateresponse=axios.post('http://localhost:3000/api/selectstate')
    .then(res=>{
     console.log("state",res.data);
     setState(res.data.data)
     setCity()
    //  statedetails=res.data
    //  statedetails=State.map((st)=>{console.log(st.StateName)})
     
     console.log(State)
   })
   .catch(err=>{
     console.log(err);
   })
  //  const cityresponse=axios.post('http://localhost:3000/api/selectcity')
  //  .then(res=>{
  //   console.log("city",res.data);
    
  // })
  // .catch(err=>{
 
  //   console.log(err);
  // })
 
  //  const localityesponse=axios.post('http://localhost:3000/api/selectlocality')
  //  .then(res=>{
  //   console.log("locality",res.data);
 
  // })
  // .catch(err=>{
  //   console.log(err);
  // })
  // console.log(State.data[0].StateName)
   },[]);
  
  return (
    <>
      <Select
        style={{
          width: 220,
          margin:20
        }}
      >
        {State.map((element) => {
          console.log(element)
            return <Select.Option key={element.Id} value={element.StateName}>{element.StateName}</Select.Option>
       })}
       
         </Select>
      {/* <Select
        style={{
          width: 120,
        }}
        value={secondCity}
        onChange={onSecondCityChange}
        options={cities.map((city) => ({
          label: city,
          value: city,
        }))}
      /> */}
    </>
  );
};
export default Search;
import { useEffect, useState, useRef, useContext } from "react";
import "../styles/propinfo.css";
import "antd/dist/antd.css";
import pic from "../assets/unnamed.png";
import locpic from "../assets/ec-location@2x.png";
import { AutoComplete } from "antd";
import { Input, Button, Modal, Card, Steps, Spin } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";
import GoogleMapComponent from "./GoogleMapComponent";

export default function Location({ propertyname }) {
  const {
    locationValue,
    addToLocations,
    textNum,
    multispacetrim,
    latlong,
    getLatLongfunc,
    changescheckingfunc,
    basic,
    propInput,
    onboardapi,
    warsoftapi,
    property_update,
    logo_img,
  } = useContext(PropertyContext);

  const navigate = useNavigate();

  const [Addrsline1, setAddrsline1] = useState(locationValue[0]?.Addrsline1);
  const [Addrsline2, setAddrsline2] = useState(locationValue[0]?.Addrsline2);
  const [StateId, setStateId] = useState(locationValue[0]?.StateId);
  const [CityId, setCityId] = useState(locationValue[0]?.CityId);
  const [localityId, setLocalityId] = useState(locationValue[0]?.localityId);
  const [Pincode, setPincode] = useState(locationValue[0]?.Pincode);
  const [Directions, setDirections] = useState(locationValue[0]?.Directions);
  const [open, setOpen] = useState(false);
  const [Statename, setStatename] = useState(locationValue[0]?.Statename);
  const [Cityname, setCityname] = useState(locationValue[0]?.Cityname);
  const [localityname, setlocalityname] = useState(
    locationValue[0]?.localityname
  );
  const [latlng, setlatlng] = useState(locationValue[0]?.latlng);
  const [errormessagearr, setmessagearr] = useState([]);
  const [stategstnum, setstategstnum] = useState("");
  const [opnemodalerror, setopenmodalerror] = useState(false);
  const [HBAddress, setHBAddress] = useState("");
  const Addresline1ref = useRef();
  const Addresline2ref = useRef();
  const Pincoderef = useRef();
  const directionref = useRef();
  const [State, setState] = useState([]);
  const [City, setCity] = useState([]);
  const [Locality, setLocality] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataChange, setDataChange] = useState("");
  const [openprevious, setOpenprevious] = useState("");
  const [loadmap, setLoadmap] = useState(true);
  const [locid, setlocid] = useState();

  const handleCancel = () => {
    setOpen(false);
  };
  const handleCancelerror = () => {
    setopenmodalerror(false);
    setOpenprevious(false);
  };

  const options = State?.map((element, i) => {
    return {
      key: i,
      value: element.StateName,
      label: element.StateName,
      StateId: element.Id,
    };
  });

  const optionscity = City?.map((element, i) => {
    return {
      key: i,
      value: element.CityName,
      label: element.CityName,
      CityId: element.Id,
    };
  });
  const optionslocality = Locality?.map((element, i) => {
    return {
      key: i,
      value: element.Locality,
      label: element.Locality,
      localityId: element.Id,
    };
  });

  const onSelect = (value, option) => {
    setStateId(option.StateId);
    setStatename(option.value);
    const stdata = {
      StateId: option.StateId,
    };

    const cityresponse = axios
      .post(`${onboardapi}/selectcity`, stdata)
      .then((res) => {
        setCity(res.data.data);
      })
      .catch((err) => {
        
      });
  };
  const onSelectcity = (value, option) => {
    setCityname(option.value);
    setCityId(option.CityId);
    const stdata = {
      cityid: option.CityId || locationValue[0]?.CityId,
    };

    const localityresponse = axios
      .post(`${onboardapi}/selectlocality`, stdata)
      .then((res) => {
        setLocality(res.data.data);
      })
      .catch((err) => {
        
      });
  };

  const onSelectlocality = (value, option) => {
    setlocalityname(option?.value);
    setLocalityId(option?.localityId);
    setlocid(option?.localityId);
  };

  const gethbaddgstnum = () => {
    const statenameselect = {
      StateId: StateId || locationValue[0]?.StateId,
    };
    axios
      .post(`${onboardapi}/SelectStatename`, statenameselect)
      .then((res) => {
        setstategstnum(res.data.data[0]?.GSTNumber);
        setHBAddress(res.data.data[0]?.HBAddress);
        setLoadmap(true);
      })
      .catch((err) => {
      
      });
  };

  useEffect(() => {
    gethbaddgstnum();
  }, []);
  const { TextArea } = Input;

  const Getlocation = () => {
    //getlocation
    setLoadmap(true);
    const datalocality = {
      cityid: CityId || locationValue[0]?.CityId,
      localityname: localityname || locationValue[0]?.localityname,
    };
    const responselocality = axios
      .post(`${onboardapi}/insertlocality`, datalocality)
      .then((res) => {
        setLocalityId(res?.data[0][0]?.Id);
      })
      .catch((err) => {
        
      });

    //get latlong
    const mapresponse = axios
      .post(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${basic[0]?.propertyname},${Addrsline1 + " " + Addrsline2 || basic[0]?.propaddress},${Cityname},${locationValue[0]?.Statename},+India,&key=AIzaSyA64hWNaMkhU56Bz0nix2TOzJJf2DoqT0A`
      )
      .then((res) => {
        setlatlng(res.data.results[0].geometry.location);

        getLatLongfunc(res.data.results[0].geometry.location);
      })
      .catch((err) => {
        
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Statename !== "") {
      axios
        .post(`${onboardapi}/selectstate`)
        .then((res) => {
          // let state_id = res.data.data.filter(item => item.StateName === locationValue[0]?.Statename);
          // setStateId(state_id[0]?.Id);
          setState(res.data.data);
          // addToLocations([{...locationValue[0],StateId:state_id[0]?.Id}])
        })
        .catch((error) => {});
    }

    if (Cityname !== "") {
      const stdata = {
        StateId: locationValue[0]?.StateId,
      };
      axios
        .post(`${onboardapi}/selectcity`, stdata)
        .then((res) => {
          setCity(res.data.data);
        })
        .catch((err) => {
          
        });
    }

    if (localityname !== "") {
      const stdata = {
        cityid: locationValue[0]?.CityId,
      };

      const localityresponse = axios
        .post(`${onboardapi}/selectlocality`, stdata)
        .then((res) => {
          setLocality(res.data.data);
        })
        .catch((err) => {
          
        });
    }
  }, []);

  

  const showModal = () => {
    setmessagearr((pre) => []);
    let add1 = document.querySelector("#Addrsline1");
    let add2 = document.querySelector("#Addrsline2");
    let loc = document.querySelector("#state");
    let city = document.querySelector("#city");
    let Locality = document.querySelector("#Locality");
    let Pincode = document.querySelector("#Pincode");
    let Directions = document.querySelector("#Directions");

    let remove_error = document.querySelectorAll(".border_red");

    remove_error.forEach((item) => item.classList.remove("border_red"));

    if (add1?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the address line 1"]);
      add1.classList.add("border_red");
    }

    if (add2?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the  address line 2"]);
      add2.classList.add("border_red");
    }
    if (loc?.value === "") {
      setmessagearr((pre) => [...pre, "* Please select the state"]);
      loc?.parent?.parent?.classList.add("border_red");
      loc?.closest(".ant-select-selector").classList.add("border_red");
    } else {
      let stateval = State.map((item) =>
        loc?.value.includes(item.StateName)
      ).some((item) => item === true);
    }
    if (StateId === "" && loc?.value !== "") {
      setmessagearr((pre) => [...pre, "* Please select state from the list"]);
    }
    if (city?.value === "") {
      setmessagearr((pre) => [...pre, "* Please select the city"]);
      city?.closest(".ant-select-selector").classList.add("border_red");
    }

    if (CityId === "" && city?.value !== "") {
      setmessagearr((pre) => [...pre, "* Please select city from the list"]);
    }

    if (Locality?.value === "") {
      setmessagearr((pre) => [...pre, "* Please select the locality"]);
      Locality?.closest(".ant-select-selector").classList.add("border_red");
    }

    if (Pincode?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the pincode"]);
      Pincode.classList.add("border_red");
    } else {
      if (Pincode?.value.length < 6) {
        setmessagearr((pre) => [...pre, "* Pincode must be 6 digits"]);
        Pincode.classList.add("border_red");
      }
    }
    if (Directions?.value === "") {
      setmessagearr((pre) => [
        ...pre,
        "* Please enter the Directions & Landmarks",
      ]);
      Directions.classList.add("border_red");
    }
    if (latlng?.lat === undefined || loadmap === false) {
      setmessagearr((pre) => [...pre, "* Click load map to get location"]);
    }

    if (
      add1?.value === "" ||
      add2?.value === "" ||
      loc?.value === "" ||
      city?.value === "" ||
      Locality?.value === "" ||
      Pincode?.value === "" ||
      Pincode?.value.length < 6 ||
      Directions?.value === "" ||
      StateId === "" ||
      CityId === "" ||
      latlng?.lat === undefined ||
      loadmap === false
    ) {
      setOpen(true);
    } else {
      setIsLoading(true);

      let locrr = [
        {
          Addrsline1:
            multispacetrim(Addrsline1) || locationValue[0]?.Addrsline1,
          Addrsline2:
            multispacetrim(Addrsline2) || locationValue[0]?.Addrsline2,
          Statename: Statename || locationValue[0]?.Statename,
          Cityname: Cityname || locationValue[0]?.Cityname,
          localityname: localityname || locationValue[0]?.localityname,
          StateId: StateId || locationValue[0]?.StateId,
          CityId: CityId || locationValue[0]?.CityId,
          localityId: locid || localityId || locationValue[0]?.localityId,
          Pincode: Pincode.value || locationValue[0]?.Pincode,
          Directions:
            multispacetrim(Directions.value) || locationValue[0]?.Directions,
          latlng: latlng || locationValue[0]?.latlng,
          stategstnum: stategstnum || locationValue[0]?.stategstnum,
          HBAddress: HBAddress || locationValue[0]?.HBAddress,
        },
      ];
      addToLocations(locrr);
      // setIsLoading(true);
      navigate("/facilities");

      const datalocality = {
        cityid: CityId,
        localityname: localityname,
      };

      const responselocality = axios
        .post(`${onboardapi}/insertlocality`, datalocality)
        .then((res) => {
          setlocalityname(localityname);
        })
        .catch((err) => {
        
        });
    }
  };
  const okpreviouspage = () => {
    navigate("/");
  };

  const showprevModal = () => {
    // let add1 = document.querySelector("#Addrsline1");
    // let add2 = document.querySelector("#Addrsline2");
    // let loc = document.querySelector("#state");
    // let city = document.querySelector("#city");
    // let Locality = document.querySelector("#Locality");
    // let Pincode = document.querySelector("#Pincode");
    // let Directions = document.querySelector("#Directions");

    // if (
    //   add1?.value === "" &&
    //   add2?.value === "" &&
    //   loc?.value === "" &&
    //   city?.value === "" &&
    //   Locality?.value === "" &&
    //   Pincode?.value === "" &&
    //   Directions?.value === ""
    // ) {
    //   navigate("/");
    // } else {
    //
    //   if (dataChange !== "") {
    //     setmessagearr([dataChange]);
    //     setOpenprevious(true);
    //   } else {
    //     navigate("/");
    //   }
    // }
    navigate("/");
  };

  const receivelatlong = (value) => {
    setlatlng(value);
    getLatLongfunc(value);
  };
  useEffect(() => {
    if (basic[0]?.propertyname === "" || basic[0]?.propertyname === undefined) {
      navigate("/");
    }

    if (latlng === "") {
      setlatlng({ lat: 28, lng: 77 });
    }

    // Getlocation();
  }, []);

  return (
    <div>
      {isLoading && (
        <div className="loaderloading">
          <div className="example">
            <Spin />{" "}
            <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
              Loading ...
            </span>
          </div>
        </div>
      )}
      <nav className="row">
        <a className="navbar-brand col-md-2 text-center mobile-show" href="#">
          <img className="" src={logo_img} alt="" width={"120px"} height={"85px"} />
        </a>

        <span className="text-center col-md-12 d-flex justify-content-center align-items-center">
          <h3>
            <b>Property Onboarding</b>
          </h3>
        </span>
      </nav>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-md-2 no-border-l h100"
            style={{ background: "#fff", borderLeft: "none" }}
          >
            <Card className=" col-md-12 cursor-not-allowed h-100 side-menu">
              <Steps
                size="small"
                className="mt-md-5 mobile-hide"
                direction="vertical"
                current={1}
              >
                <Steps.Step title={<NavLink to="/">Basics</NavLink>} />
                <Steps.Step
                  title={<NavLink to="/location">Location</NavLink>}
                />
                <Steps.Step
                  title={<NavLink to="/facilities">Facilities</NavLink>}
                />
                <Steps.Step
                  title={<NavLink to="/roomdet">Room details</NavLink>}
                />
                <Steps.Step title={<NavLink to="/photos">Photos</NavLink>} />
                <Steps.Step title={<NavLink to="/Profile">Contact</NavLink>} />
                <Steps.Step title={<NavLink to="/Publish">Publish</NavLink>} />
              </Steps>
              <a
                className="navbar-brand col-md-2 text-center logo-display"
                href="#"
              >
                <img
                  className="logo_name"
                  src={logo_img}
                  alt=""
                  width="100%"
                />
              </a>
              <p className="mt-sm-3 desk_browser" style={{fontSize: "12px"}}><span className="fw-bold" style={{fontSize: "14px",marginBottom:"10px"}}>Supported Browsers : </span><br/> <span style={{margin:"0px",lineHeight:"10px"}}></span>1. Google Chrome,<br/>2. Microsoft Edge,<br/>3. Firefox.</p>
            </Card>
          </div>
          <div
            className="col-md-10 location layout-ml"
            style={{ background: "rgb(227 245 255)" }}
          >
            <div className="row">
              <div className="col-sm-6 p-3">
                <h3>Put yourself on the map.</h3>
                <p>Where will your guests be staying?</p>
              </div>
              <div className="col-sm-6 mt-2">
                <img src={locpic} alt="" height={"120px"} />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-sm-12 col-lg-9">
                <h5>Location</h5>
                <p>
                  Guests will only receive your exact address once they have
                  confirmed a booking.
                </p>
                <Card
                  className="cardbgcolor"
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  
                  <div className="row mt-sm-2">
                    <div className="col-sm-2">
                      <label>
                        <b>GSTIN State</b>
                      </label>
                      <br />
                    </div>
                    <div className="col-sm-4 l_city">
                      {/* <AutoComplete
                        options={options}
                        style={{ width: "100%" }}
                        // onChange={onChangeautocomplete}
                        onChange={(e) => {
                          setStateId("");
                          setStatename(e);
                          setCityname("");
                          setDirections("");
                          setlocalityname("");
                          setPincode("");
                          changescheckingfunc("Location details updated");
                          setDataChange(
                            " You have unsaved data, do you want to continue"
                          );
                          setCity([]);
                          setLocality([]);
                        
                        }}
                       
                        onSelect={onSelect}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        onBlur={gethbaddgstnum}
                        placeholder="State"
                        value={Statename}
                        id="state"
                      /> */}
                      <Input readOnly value={Statename} />
                    </div>
                    <div className="col-sm-2">
                      <label>
                        <b>
                          City&nbsp;<span className="m-red">*</span>
                        </b>
                      </label>
                      <br />
                    </div>
                    <div className="col-sm-4 l_city">
                      <AutoComplete
                        options={optionscity}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setCityname(e);
                          setCityId("");
                          setDirections("");
                          setlocalityname("");
                          setPincode("");
                          changescheckingfunc("Location details updated");
                          setDataChange(
                            " You have unsaved data, do you want to continue?"
                          );
                          setCity(City);
                          setLocality([]);
                          setLoadmap(false);
                        }}
                        onSelect={onSelectcity}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        placeholder="City"
                        value={Cityname}
                        id="city"
                      />
                    </div>
                  </div>

                  <div className="row mt-0 mt-md-2">
                    <div className="col-sm-2">
                      <label>
                        <b>
                          Locality&nbsp;<span className="m-red">*</span>
                        </b>
                      </label>
                    </div>
                    <div className="col-sm-4 l_city">
                      <AutoComplete
                        options={optionslocality}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setlocalityname(e);
                          setLocalityId("");
                          setPincode("");
                          setDirections("");
                          changescheckingfunc("Location details updated");
                          setDataChange(
                            " You have unsaved data, do you want to continue?"
                          );
                          setLoadmap(false);
                        }}
                        onSelect={onSelectlocality}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        placeholder="Locality"
                        value={localityname}
                        id="Locality"
                      />
                    </div>
                    <div className="col-sm-2">
                      <label>
                        <b>
                          Pincode&nbsp;<span className="m-red">*</span>
                        </b>
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <Input
                        placeholder="Pincode"
                        value={Pincode}
                        //pattern="[0-9]*"
                        pattern="[0-9]"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setPincode(textNum(e));
                          changescheckingfunc("Location details updated");
                          setDataChange(
                            " You have unsaved data, do you want to continue?"
                          );
                        }}
                        ref={Pincoderef}
                        id="Pincode"
                        type="text"
                        maxLength={6}
                        min={0}
                      ></Input>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-sm-2">
                      <label>
                        <b>
                          Address Line&nbsp;1&nbsp;
                          <span className="m-red">*</span>
                        </b>
                      </label>
                      <br />
                    </div>
                    <div className="col-sm-4">
                      <TextArea
                        rows={2}
                        value={Addrsline1}
                        onChange={(event) => {
                          setAddrsline1(
                            event.target.value.replaceAll("  ", " ")
                          );
                          changescheckingfunc("Location details updated");
                          setDataChange(
                            " You have unsaved data, do you want to continue?"
                          );
                        }}
                        ref={Addresline1ref}
                        style={{ width: "100%", resize: "none" }}
                        id="Addrsline1"
                        onInput={propInput}
                      />
                    </div>
                    <div className="col-sm-2">
                      <label>
                        <b>
                          Address Line&nbsp;2&nbsp;
                          <span className="m-red">*</span>
                        </b>
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <TextArea
                        value={Addrsline2}
                        rows={2}
                        onChange={(event) => {
                          setAddrsline2(
                            event.target.value.replaceAll("  ", " ")
                          );
                          changescheckingfunc("Location details updated");
                          setDataChange(
                            " You have unsaved data, do you want to continue?"
                          );
                        }}
                        ref={Addresline2ref}
                        style={{ width: "100%", resize: "none" }}
                        id="Addrsline2"
                        onInput={propInput}
                      />
                    </div>
                  </div>
                  <div className="row mt-sm-2">
                    <div className="col-sm-2">
                      <label>
                        <b>
                          <span className="direction_w-100">
                            Directions&nbsp;&{" "}
                          </span>
                          Landmarks&nbsp;<span className="m-red">*</span>
                        </b>
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <TextArea
                        placeholder="Directions"
                        onChange={(event) => {
                          setDirections(
                            event.target.value.replaceAll("  ", " ")
                          );
                          changescheckingfunc("Location details updated");
                          setDataChange(
                            " You have unsaved data, do you want to continue?"
                          );
                        }}
                        ref={directionref}
                        value={Directions}
                        id="Directions"
                        style={{ resize: "none" }}
                        onInput={propInput}
                      ></TextArea>
                    </div>
                    <div className="col-sm-2"></div>
                    <div className="col-sm-4 mt-2 mt-md-0 mb-4">
                      <Button
                        type="primary"
                        style={{ minWidth: "100px", marginRight: "5px" }}
                        onClick={Getlocation}
                      >
                        {/* <NavLink to="/" onClick={showprevModal}>Previous</NavLink> */}
                        Load Map
                      </Button>
                      <br />
                      <small>(Click load map to update locality on map)</small>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12" style={{ minHeight: "340px" }}>
                      <h5 className="mt-sm-3">Map location</h5>
                      <GoogleMapComponent
                        receivelatlong={receivelatlong}
                        latlngdet={
                          latlng ||
                          (latlong && latlong) ||
                          locationValue[0]?.latlng || {
                            lat: 10.9573262,
                            lng: 76.9856282,
                          }
                        }
                        localityname={localityname}
                      />
                    </div>
                  </div>
                </Card>
                <div className="float-end mt-3 mt-sm-3 mb-4">
                  <Button
                    type="primary"
                    style={{ minWidth: "100px", marginRight: "5px" }}
                    onClick={showprevModal}
                  >
                    {/* <NavLink to="/" onClick={showprevModal}>Previous</NavLink> */}
                    Previous
                  </Button>

                  <Button
                    type="primary"
                    className="ms-sm-3"
                    style={{ minWidth: "100px" }}
                    onClick={showModal}
                  >
                    Save & Continue
                    {/* <NavLink to="/facilities" ></NavLink> */}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="error_msg"
        title="Error message"
        open={open}
        onCancel={handleCancel}
        footer={[
          <Button key="back" type="primary" onClick={handleCancel}>
            Close
          </Button>,
        ]}
        maskClosable={false}
      >
        {errormessagearr &&
          errormessagearr.map((item, index) => <p key={index}>{item}</p>)}
      </Modal>

      <Modal
        className="error_msg"
        title="Error message"
        open={opnemodalerror}
        onCancel={handleCancelerror}
        footer={[
          <Button key="back" type="primary" onClick={handleCancelerror}>
            Close
          </Button>,
        ]}
        maskClosable={false}
      >
        * This Property has already created
      </Modal>
      <Modal
        className="error_msg"
        title="Error message"
        open={openprevious}
        onCancel={handleCancelerror}
        footer={[
          <Button key="back" type="primary" onClick={okpreviouspage}>
            Close
          </Button>,
          <Button key="back1" type="primary" onClick={handleCancelerror}>
            Cancel
          </Button>,
        ]}
        maskClosable={false}
      >
        * You have un saved data, do you want to continue?
      </Modal>
    </div>
  );
}
